/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, defineMessages } from 'react-intl';
import { pushToGA, events } from '../../utils/gtmUtils';
import { post } from "../../utils/api";
import { NavAccount } from "@d-lighted/design-system";
import { BreadCrumb } from "../elements/BreadCrumb";
import {
  getCurrentUser,
  getPlanLabel,
  isTrialExpired,
  getTrialExpireDate,
  isBookingTypeIrregular,
  getSubdomain,
} from "../../utils/userUtils";
import AccountIcon from "../../assets/img/account.svg";
import IntegrationIcon from "../../assets/img/integration.svg";
import LogoutIcon from "../../assets/img/logout.svg";
import CopyLink from "../../assets/img/copylink.svg";
import AdhocIcon from "../../assets/img/icon_adhoc.svg";
import { generateLinkURL } from "../../views/home/utils/utils";
import OnlineSwitch from "../elements/OnlineSwitch";
import LanguageSelector from "../elements/LanguageSelector";
import PlanStatusLabel from "../elements/PlanStatusLabel";
import copy from "copy-to-clipboard";
import { MOBILEBREAKPOINT } from "../../constants/breakpoint";
import { useAuth } from "../../hooks/useAuth";
import PropTypes from "prop-types";

const Header = styled.header`
  height: 58.5px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: ${(props) =>
    props.isMobileDashboard ? "none" : "solid 1px #d8d8d8"};
  background-color: ${(props) =>
    props.isMobileDashboard ? "#f7f9f9" : " #fff"};
`;

const TemplateHeader = styled.header`
  height: 60px;
  width: 100%;
  text-align: center;
  background-color: #03AED4;
  color: white;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
`;

const HeaderLeft = styled.div`
  margin-top: 5px;
  display: inline-flex;
`;

const HeaderRight = styled.div`
  position: absolute;
  right: 100px;
  top: 10px;
  @media (max-width:${MOBILEBREAKPOINT}) {
    display: none;
  }
`;

const HeaderNav = styled.div`
  position: absolute;
  right: 5px;
  top: -10px;
`;

const LinkWrapper = styled.div`
  color: #00bbb5;
  font-size: 11px;
  margin-top: 18px;
  text-decoration: underline;
  cursor: pointer;
`;

const LinkText = styled.span`
  top: -4px;
  position: relative;
  color: #00bbb5;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 30%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 14px;
    top: 0;
  }
`;

const Image = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 8px;
`;

const HelpCenter = styled.a`
  display: inline-block;
  color: #00bbb5;
  text-decoration: none;
`;

const CustomReactTooltip = styled(ReactTooltip).attrs({
  className: "custom-tooltip",
})`
  &.custom-tooltip {
    color: #fff;
    border-radius: 6px;
    width: 85px;
    height: 25px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;

    transition: visibility 0s 0.375s, opacity 0.375s ease-in-out !important;

    &.show {
      transition: visibility 0s 0s, opacity 0.375s ease-in-out !important;
    }
  }
`;

const TrialExpriration = styled.div`
  width: 100%;
  padding: 16px 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
  background-color: #FD7166;
  cursor: pointer;
`;

const FixedRow = styled.div`
  position: fixed;
  z-index: 2;
  width: 100%;
`;

const messages = defineMessages({
  helpCenter: {
    id: "components.layouts.header.helpCenter",
    defaultMessage: "Help Center",
  },
  accountInfo: {
    id: "components.layouts.header.navMenu.accountInfo",
    defaultMessage: "Account Info",
  },
  integrations: {
    id: "components.layouts.header.navMenu.integrations",
    defaultMessage: "Integrations",
  },
  logout: {
    id: "components.layouts.header.navMenu.logout",
    defaultMessage: "Account Info",
  },
  scheduling: {
    id: "components.layouts.header.navMenu.scheduling",
    defaultMessage: "Create temporary event",
  },
  trialExpire: {
    id: 'components.layouts.header.trialExpire',
    defaultMessage: 'Your trial will end in {date} days. To continue using the service after the trial ends, please select a plan and register a credit card.',
  },
  copied: {
    id: 'common.copied',
    defaultMessage: 'Copied',
  },
  editingTemplate: {
    id: 'components.layouts.header.navMenu.editingTemplate',
    defaultMessage: 'Editing Template (when saved, immediately reflected on {count} booking pages)',
  },
});

function HeaderComponents(props) {
  const { isMobileDashboard, location, hideRightComponent } = props;
  const URL =
    process.env.REACT_APP_SCHEDULING_HELP_ROOT ||
    "https://staging.scheduling.help.receptionist.jp/";
  const currentUser = getCurrentUser();
  const { data, isSuccess } = useSelector((state) => state.calendarSetting);
  const [linkURL, setLinkURL] = useState("");
  const { uid, settings } = useParams();
  const isTemplatePageForURL = [settings, ...location.pathname.split('/')].includes('templates');
  const isTemplatePage = [settings].includes('templates');
  const { authActions } = useAuth();
  const isIrregular = isBookingTypeIrregular();

  const openWebApp = async(path='/') => {
    const accessUrl = await authActions.getOneTimeAccessUrl(path);
    if((/https?:\/\//g).test(accessUrl)) {
      pushToGA({ ...events.click_link_app(path) });
      window.open(accessUrl, "_blank");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const { alias, durations } = data;
      setLinkURL(generateLinkURL(alias, uid, isIrregular, durations));
    } else {
      setLinkURL("");
    }
  }, [data]);

  const helpGAEvent = () => {
    pushToGA({ ...events.click_link_help("/") });
  };

  const handleClickCardRegister = async () => {
    let { data: { token } } = await post('/auth/one_time_token');
    const client = JSON.parse(localStorage.getItem('auth_headers')).client;

    const path = `${window.location.protocol}//${getSubdomain()}${process.env.REACT_APP_WEB_PATH}/sign_in`;
    window.open(`${path}?one_time_token=${token}&client_id=${client}&return_to=/info/pricing/scheduling`);
  };

  const accountList = [
    {
      label: <FormattedMessage {...messages.accountInfo} />,
      icon: AccountIcon,
      onClick: () => {
        openWebApp(`/info/account`);
      },
    },
    {
      label: <FormattedMessage {...messages.integrations} />,
      icon: IntegrationIcon,
      onClick: () => {
        openWebApp('/info/integrations');
      },
    },
    {
      label: <FormattedMessage {...messages.logout} />,
      icon: LogoutIcon,
      onClick: () => {
        window.localStorage.removeItem("auth_headers");
        window.localStorage.removeItem("refresh_token");
        window.localStorage.removeItem("currentUser");
        window.location.reload();
      },
    },
  ];

  const multiguestRoutes = ["multiguest", "multiguestList", "multiguestFinalize", "multiguestConfirm"];

  const schedulingLink = [
    {
      label: <FormattedMessage {...messages.scheduling} />,
      icon: AdhocIcon,
      onClick: () => {
        openWebApp(`/appointments/new/scheduling`);
      },
    },
  ];

  return (
    <FixedRow>
      {isTrialExpired() &&
        <TrialExpriration onClick={handleClickCardRegister}>
          <FormattedMessage  {...messages.trialExpire} values={{ date: getTrialExpireDate() }} />
        </TrialExpriration>
      }
      <Header isMobileDashboard={isMobileDashboard}>
        <HeaderLeft>
          <BreadCrumb label={props.label} {...props}/>
          {!isTemplatePageForURL && uid && !multiguestRoutes.includes(props.label) &&
            <>
              <LinkWrapper
                data-for="tooltip"
                data-tip={props.intl.formatMessage({ ...messages.copied })}
                data-event="click"
                data-event-off="click"
                onClick={(_e) => {
                  pushToGA({ ...events.copy_bookingPage_url("fixed", "Bp/General") });
                  copy(linkURL);
                }}
              >
                <LinkText>{linkURL}</LinkText>
                <Image src={CopyLink}/>
              </LinkWrapper>
              <CustomReactTooltip
                isCapture={true}
                id="tooltip"
                delayHide={1000}
                place="bottom"
                effect="solid"
                backgroundColor="#6e52ff"
              />
              <OnlineSwitch data={data} useNotify={props.useNotify} />
            </>
          }
      </HeaderLeft>
      {!hideRightComponent && 
        <>
          <HeaderRight>
            <HelpCenter
              href={URL}
              alt="Help center"
              target="_blank"
              onClick={() => helpGAEvent()}
            >
              <FormattedMessage {...messages.helpCenter} />
            </HelpCenter>
            <LanguageSelector />
            <PlanStatusLabel planStatus={getPlanLabel()} />
          </HeaderRight>
          <HeaderNav>
            <NavAccount
              currentUser={currentUser}
              accountList={schedulingLink}
              showCompanyInfo={false}
              companyList={accountList}
              pr={["8px", "25px"]}
            />
          </HeaderNav>
        </>
      }
    </Header>
    {isTemplatePage && !isMobileDashboard && (
      <TemplateHeader>
        <FormattedMessage 
          {...messages.editingTemplate}
          values={{
            count: data?.templateBookingCalendarsCount || 0,
          }}
        />
      </TemplateHeader>
    )}
  </FixedRow>
  );
}

HeaderComponents.defaultProps = {
  companyList: [],
  hideRightComponent:false
};

HeaderComponents.propTypes = {
  companyList: PropTypes.oneOfType([PropTypes.array]),
};

export default HeaderComponents;
