export const defaultCompleteEmailLeadText = `平素は格別のご高配を賜り、厚く御礼申し上げます
  本メールは、日程調整ツール「調整アポ」よりお送りしております。
  下記の内容にてお約束をさせていただきました。
  We would like to express our thanks for your loyal patronage.
  This E-mail is sent from Chousei-Apo scheduling system.
  We got the following appointment with you.
`;
export const reminderEmailLeadText = `平素は格別のご高配を賜り、厚く御礼申し上げます。
  下記のアポイントメントにつきまして, 当日となりましたのでリマインドのご連絡をいたします。
  We would like to express our thanks for your loyal patronage.
  Regarding the following appointments, we will contact you to remind you that it is the day of the event.
`;
export const AppointmentreminderEmailClosingText = `
  ※このメールにお心当たりのない場合は破棄してください。
  ※If you do not requested this email, please ignore it.
`;
export const defaultPersonInCharge = `
  {自社の会社名}\n{自社の担当者全員の氏名}
`;
export const defaultPersonInDest = `
  {自社の会社名}\n{自社の担当者名}
`;
