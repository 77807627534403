import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'views.appointments.modules.multiGuest.title',
    defaultMessage: 'Title',
  },
  titlePlaceholder: {
    id: 'views.appointments.modules.multiGuest.title.placeholder',
    defaultMessage: 'Example) Joint meeting between Mr. ●● and Mr. ▲▲',
  },
  range: {
    id: 'views.appointments.modules.multiGuest.range',
    defaultMessage: 'Data range available for scheduling',
  },
  rangeError: {
    id: 'views.appointments.modules.multiGuest.range.error',
    defaultMessage: 'You can only specify a range 30days from the current day.',
  },
  rangeTooltip: {
    id: 'views.appointments.modules.multiGuest.rangeTooltip',
    defaultMessage: 'Data range available for scheduling',
  },
  create: {
    id: 'views.appointments.modules.multiGuest.create',
    defaultMessage: 'Create multiguest page URL',
  },
  durations: {
    id: 'views.appointments.modules.multiGuest.durations',
    defaultMessage: 'Durations',
  },
  durationsTooltip: {
    id: 'views.appointments.modules.multiGuest.durationsTooltip',
    defaultMessage: '「時間枠区切り」は30分固定です。会議時間を60分、90で選択した場合も、予約ページの時間選択枠は30分間隔で表示されます。',
  },
  d15: {
    id: 'views.meetings.pages.meetings.durations.15',
    defaultMessage: '15Min',
  },
  d30: {
    id: 'views.meetings.pages.meetings.durations.30',
    defaultMessage: '30Min',
  },
  d60: {
    id: 'views.meetings.pages.meetings.durations.60',
    defaultMessage: '60Min',
  },
  d90: {
    id: 'views.meetings.pages.meetings.durations.90',
    defaultMessage: '90Min',
  },
  notifySuccess: {
    id: 'notify.multiguestUrl.create.success',
    defaultMessage: 'Multiguest URL created successfully.',
  },
  notifyError: {
    id: 'notify.multiguestUrl.create.error',
    defaultMessage: 'Multiguest URL created couldn\'t be created.',
  },
  returnToDashboard: {
    id: 'components.layouts.appointmentsTable.returnToDashboard',
    defaultMessage: 'Return to Dashboard',
  },
  returnToMultiguestList: {
    id: 'views.appointments.modules.multiGuest.returnToMultiguestList',
    defaultMessage: 'Return to Multiguest list',
  },
  notifyMultiguestScheduledEventsAppointmentErrorTitle: {
    id: 'notify.multiguestCalendars.appointment.error.title',
    defaultMessage: 'Unable to create multiguest appointment',
  },
  notifyMultiguestScheduledEventsErrorTitle: {
    id: 'notify.multiguestCalendars.list.error.title',
    defaultMessage: 'Unable to retrieve multiguest calendars',
  },
  notifyMultiguestScheduledEventsError: {
    id: 'notify.multiguestCalendars.list.error',
    defaultMessage: 'There was an error while retrieving Multiguest calendar lists',
  },
  notifyDeleteSuccess: {
    id: 'notify.multiguestUrl.delete.success',
    defaultMessage: 'Multiguest calendar deleted successfully.',
  },
  notifyDeleteError: {
    id: 'notify.multiguestUrl.delete.error',
    defaultMessage: 'Multiguest calendar couldn\'t be deleted.',
  },
  description: {
    id: 'views.appointments.modules.multiGuest.description',
    defaultMessage: 'Description',
  },
  descriptionPlaceholder: {
    id: 'views.appointments.modules.multiGuest.description.placeholder',
    defaultMessage: 'We are arranging a date for a meeting between three or more parties.\nPlease select several (as many as possible) convenient dates from the calendar below.\nWe will separately confirm the date and time from among the selected candidate dates, together with the candidate dates of other participants.'
  },
  completedMessageWebpage: {
    id: 'views.appointments.modules.multiGuest.completedMessageWebpage',
    defaultMessage: 'Candidate date entry completion statement',
  },
  completedMessageWebpagePlaceholder: {
    id: 'views.appointments.modules.multiGuest.completedMessageWebpage.placeholder',
    defaultMessage: 'Save changes',
  },
  completeMessageWebpageTooltip: {
    id: 'views.appointments.modules.multiGuest.completedMessageWebpage.tooltip',
    defaultMessage: 'After the customer confirms the proposed date and time\nThis is the message that appears on the screen.'
  },
  multiguestUrlCreated: {
    id: 'views.appointments.modules.multiGuest.created',
    defaultMessage: 'Multiguest URL created',
  },
  multiguestUrlInformation: {
    id: 'views.appointments.modules.multiGuest.urlInformation',
    defaultMessage: 'Please share this URL with your guests.',
  },
  zoomNotIntegrated: {
    id: 'notify.zoomNotIntegrated',
    defaultMessage: 'Appointment creation failed because Zoom is not integrated.',
  },
  webexNotIntegrated: {
    id: 'notify.webexNotIntegrated',
    defaultMessage: 'Appointment creation failed because Webex is not integrated',
  },
  failedToRetrieveAvailableSlots: {
    id: 'notify.multiguestCalendars.failedToRetrieveAvailableSlots',
    defaultMessage: 'Failed to retrieve available slots',
  }
});

