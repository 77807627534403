import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 71px;
  text-align: center;
  margin-bottom: 95px;
  background: #fff;
`;

export const LeadText = styled.p`
  font-size: 16px;
  color: #314143;
  margin-bottom: 32px;
  line-height: 1.62;
  text-align: left;
`;

export const EnglishText = styled.span`
  font-family: Roboto, sans-serif;
  white-space: pre-line;
`;

export const Logo = styled.img`
  margin: 40px 0;
  width: 250px;
  height: 100px;
`;

export const Image = styled.img`
  margin: 10px 0;
  width: 60px;
  height: 60px;
`;

export const MainContentWithBorder = styled.div`
  border: 1px solid #142C2B33;
  color: #324143;
  margin: 32px auto;
`;

export const MainContent = styled(MainContentWithBorder)`
  border-bottom: none;
`;

export const BusinessCardContentWrapper = styled.div`
  display: block;
  margin-top: 30px;
`;

export const BusinessCardImageWrapper = styled.div`
  display: block;
  margin: 0 auto 20px auto;
  width: 338px;
`;

export const BusinessCardImage = styled.img`
  height: 20px;
  margin: 0 20px;
`;

export const BusinessCardButtonWrapper = styled.div`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
`;

export const BusinessCardButtonLink = styled.a`
  font-family: Arial;
  text-decoration: none;
  cursor: pointer;
  display: block;
  max-width: 400px;
  border-radius: 6px;
  height: 12px;
  border-color: #004E9E;
  color: white;
  background-color: #004E9E;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px;
`;

export const BusinessCardBodyTextWrapper = styled.div`
  font-family: Arial;
  display: flex;
  margin-bottom: 28px;
  font-size: 12px;
  text-align: center;
`;

export const EmailContent = styled.div`
  display: flex;
  border-bottom: 1px solid #142C2B33;
  padding: 4px 0;
  text-align: left;
  white-space: pre-line;
`;

export const EmailContentFirstColumn = styled.div`
  width: 25%;
  background-color: #F8F9F9;
  color: #839CA2;
  padding: 4px 16px;
`;
export const EmailContentSecondColumn = styled.div`
  width: 85%;
  padding: 6px 24px;
`;

export const ClosingText = styled.p`
  margin: 60px 0 30px 0;
  color: #314143;
  font-size: 16px;
  white-space: pre-line;
`;

export const SubClosingText = styled.p`
  margin: 30px 0;
  color: #314143;
  font-size: 13px;
  text-align: center;
`
export const EmailFooter = styled.div`
  width: 600px;
  height: 370px;
  background: #F7F9F9 0% 0% no-repeat padding-box;
  margin: 0 auto;
  text-align: center;
`;

export const FooterText = styled.p`
  margin: 0 0 30px 0;
  color: #314143;
  font-size: 13px;
`;

export const ContactButton = styled.a`
  display: block;
  text-decoration: none;
  text-align: center;
  width: 174px;
  height: 40px;
  border: 1px solid #314143;
  margin: 20px auto 0;
  line-height: 40px;
`;

export const CancelAppointmentButton = styled.a`
  display: flex;
  width: 300px;
  padding: 12px 22px 13px;
  border-radius: 5px;
  border: solid 1px #d83636;
  justify-content: center;
  margin: 20px auto;
  cursor: pointer;
  background-color: #fff;
  text-decoration: none;
  color: #d83636;
`;

export const HostSectionHeader = styled.h2`
  color: #314143;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  margin: 60px 0 30px 0;"
`;

export const HostSectionText = styled.p`
  text-align: left;
  margin: 25px auto;
`;
