import React, { useEffect } from "react";
import styled from 'styled-components';
import { reduxForm, Field, formValueSelector } from "redux-form";
import { useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import {
  ImageUploader,
  Label,
  Col,
  Row,
  IconNew as Icon,
  TextArea,
  Hr,
  Button,
  PhoneBreakpoint,
  DesktopBreakpoint
} from "@d-lighted/design-system";
import { Dashboard } from "components/layouts/Dashboard";
import { actions as reduxActions } from "redux/actions";
import { FieldName, FieldIcon, FieldWrapper, FieldImageWrapper } from "components/elements/BorderedField";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import { isEnterprisePlan, isStandardPlan } from "utils/userUtils";
import { useValidation } from "utils/validation";
import * as messageIdConstants from "components/layouts/constants/messageIdConstants";
import { ClearIcon } from "../components/ClearIcon";
import { useGet } from "utils/api";
import { NoSupportWarning } from "components/layouts/NoSupportWarning";
import TemplatePermissionSelector, { convertToOption, convertToPermission } from "components/forms/TemplatePermissionSelector";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import { useCalendarEmailCustomizations } from "../hooks/useCalendarEmailCustomizations";
import { useCalendarSetting } from "hooks/useCalendarSetting";
import { EmailVariableText } from "../components/EmailVariableText";
import PlanIcon from '../../../components/elements/PlanIcon';
import messages from "../i18n/EmailNotifications";

const TCol = styled(Col)`
  margin-top: -18px;
  position: relative;
  top: 8px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 12px 10px;
  display: flex;
  border-radius: 5px;
  max-width: 400px;
  min-width: 200px;
  width: 100%;
  border: solid 1px;
  border-color: ${({ isBlocked }) => isBlocked ? 'rgba(210, 233, 231, 0.6)' : 'rgba(210, 233, 231, 1)'};
  align-items: center;

  &:hover {
    border-color: ${({ isBlocked }) => isBlocked ? 'inherited' : '#00bbb5'};
  }

  & > * {
    opacity: ${({ isBlocked }) => isBlocked ? 0.6 : 1.0};
    ${({ isBlocked }) => isBlocked && `
    pointer-events: none;
    user-select: none;
  `}
  }

  & > ${Icon} {
    opacity: 1 !important;
    pointer-events: all;
    user-select: none;
  }
`;

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = reduxActions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

const EmailNotificationFormReader = formValueSelector("EmailNotifications");

const EmailNotifications = (props) => {
  const { initialize, useNotify, pristine, submitting, intl, history, handleSubmit, invalid } = props;
  const params =  useParams();
  const { uid, settings } = params;
  const isTemplatePage = [settings].includes('templates');
  const { setTemplateUid, saveWithTemplateSettings, saveTemplateSettings } = useSavedTemplateSettings();
  const notify = useNotify(intl);
  useGet(calendarSettingRequest(uid));
  const dispatch = useDispatch();
  const notificationTypes = ["booking_complete", "reminder_email"];
  const { data } = useSelector((state) => state.calendarSetting);
  const { reminderEmailCustomization, actions, commonEmailCustomization, isSuccess } = useCalendarEmailCustomizations(notify, params?.uid);
  const reminderEmailAllowed = reminderEmailCustomization?.reminderAllowed === undefined ? true : reminderEmailCustomization?.reminderAllowed;

  const { calendarActions } = useCalendarSetting(notify, uid);
  const emailLogo = useSelector(state => EmailNotificationFormReader(state, "logo"));
  const permissions = useSelector(state => EmailNotificationFormReader(state, "permissions"));

  const { required, emptyString, inputLength, blockOfTextLength, imageSize } = useValidation(intl);
  const teamBookingPage = data?.pageType;
  
  useEffect(() => {
    actions.fetchCalendarEmailCustomizations();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (isSuccess) {
      const {
        personInCharge,
        completedMessageEmail,
        contactInformation,
        logo,
      } = commonEmailCustomization || {};
      const {
        bookingCalendarTemplateUid,
        permissions,
      } = data;
      setTemplateUid(bookingCalendarTemplateUid);
      initialize({
        personInCharge,
        completedMessageEmail,
        contactInformation,
        logo: logo?.url,
        permissions: convertToOption(permissions, intl),
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [data, commonEmailCustomization]);

  const handleEditField = (e, path) => {
    e.preventDefault();
    history.push({
      pathname: `/${settings}/${uid}/edit/email_notifications/${path}`,
    });
  };

  const handleResetLogo = () => {
    actions.resetEmailLogo(props, emailLogo);
    setTimeout(async () => {
      await saveTemplateSettings(convertToOption(data.permissions, intl), { data: {} });
    }, 300);
  };

  const accessBlockedMessage = () => {
    if(!isEnterprisePlan()){
      return messageIdConstants.isEnterpriseEmailCustomizations;
    }
    return null;
  };

  function formatBody(values) {
    let body = {
      booking_calendar_email_customizations: {
        common_email_customization: {
          person_in_charge: values.personInCharge,
          completed_message_email: values.completedMessageEmail,
          contact_information: values.contactInformation,
          logo: values?.logo,
        }
      },
    };
    if (emailLogo != null && !emailLogo.startsWith("http")) {
      body.booking_calendar_email_customizations.logo = values.logo
    }
    return body;
  }
  
  const middlewareGenerator = permissions => {
    if(isTemplatePage) {
      return promise => saveWithTemplateSettings(promise, permissions); 
    }
    
    return promise => promise
  };

  const onSubmit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

  const handleOnSubmit = async (values) => {
    const body = formatBody(values);

    const middleware = middlewareGenerator(values.permissions);
    
    const result = actions.updateCalendarEmailCustomizations(body, middleware);
    
    if(isTemplatePage) {
      result.then(result => {
        if (result == null) return;
        
        dispatch({
          type: `${reduxActions.getCalendarSetting.name}_update`,
          payload: { data: { ...data, permissions: convertToPermission(values.permissions, false)}},
        });
      });
    } else {
      calendarActions.getCalendarSetting();
    }
  };

  const enterPriseAndTeams = () => {
    return isEnterprisePlan() && teamBookingPage;
  };
  
  const typeField = ["completeEmail", "reminderEmail"];
  const templateTypeField = ["emailTypeCompletion", "emailTypeReminder"];

  return(
    <>
      <PhoneBreakpoint>
        <NoSupportWarning />
      </PhoneBreakpoint>
      <DesktopBreakpoint>
        <Dashboard>
          {enterPriseAndTeams() && (
            <>
              <Row mt="40px" mb="13px">
                <Col xs>
                  <Row>
                    <Col xs display="flex" alignItems="center">
                      <Label fontSize="15px">
                        <FormattedMessage {...messages.title} />
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {notificationTypes.map((notificationType, index) => {
                const isBlocked = !isTemplatePage && permissions?.[templateTypeField[index]]?.value === "administrator";
                
                return(
                  <Row key={notificationType} flexWrap="wrap" alignItems="center">
                    <TemplateBlocked
                      blocked={permissions?.[templateTypeField[index]]?.value}
                      fieldsStyle={{display: 'flex', flexBasis: '0', flexGrow: '1', marginBottom: '20px'}}
                      extraIndentation="8px"
                      iconTopPadding="15px"
                      unlockedFieldsBlocking={!isTemplatePage}
                      fields={
                        <Col xs display="flex" alignItems="center">
                          <Wrapper isBlocked={isBlocked}>
                            <FieldWrapper disabled={index === 1 && !reminderEmailAllowed}>
                              <FieldImageWrapper>
                                <FieldIcon
                                  name="envelope"
                                  className="far fa-envelope"
                                  color="#68878d"
                                />
                              </FieldImageWrapper>
                              <FieldName left="12px">
                                <Row display="flex" alignItems="center">
                                  <FormattedMessage {...messages[typeField[index]]} />
                                  <PlanIcon
                                    featurePlan="enterprise"
                                    ml={4}
                                    width={index === 0 ? 270 : undefined}         
                                  />
                                </Row>
                              </FieldName>
                            </FieldWrapper>
                            <Icon
                              isCursor
                              onClick={(e) => handleEditField(e, notificationType)}
                              className="material-icons"
                              color="#00bbb5"
                              fontSize="23px"
                              mr="5px"
                            >
                              edit
                            </Icon>
                          </Wrapper>
                        </Col>
                      }
                    />
                    {isTemplatePage && (
                      <Col mb="20px">
                        <TemplatePermissionSelector name={templateTypeField[index]} />
                      </Col>
                    )}
                  </Row>
                )}
              )}
              <Row mt="20px" mb="13px">
                <Col xs>
                  <Row>
                    <Col xs>
                      <Label fontSize="15px">
                        <FormattedMessage {...messages.titleCommonCustomizations} />
                      </Label>
                    </Col>
                    {isTemplatePage && (
                      <Col>
                        <TemplatePermissionSelector name="emailTypeCommon"/>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          )} 
          <PlanBlocked mt="26px" plan="standard" messageId={accessBlockedMessage()}>
            <></>
          </PlanBlocked>
          {isStandardPlan() && (
            <>
              <Row mt="26px" mb="26px">
                <Col xs>
                  <TemplateBlocked
                    blocked={permissions?.emailTypeCommon?.value}
                    header={
                      <Row>
                        <Col xs>
                          <Label>
                            {intl.formatMessage({...messages.completedMessageEmail})}
                          </Label>
                        </Col>
                        {isTemplatePage && !isEnterprisePlan() && (
                          <TCol>
                            <TemplatePermissionSelector name="emailTypeCommon"/>
                          </TCol>
                        )}
                      </Row>}
                    fields={
                      <>
                        <EmailVariableText
                          elementId="completedMessageEmail"
                          intl={intl}
                          form="EmailNotifications"
                        />
                        <Field
                          id="completedMessageEmail"
                          name="completedMessageEmail"
                          component={TextArea}
                          placeholder={intl.formatMessage({...messages.completedMessageEmailPlaceholder})}
                          validate={blockOfTextLength}
                          height="216px"
                          borderRadius="0 0 5px 5px"
                        />
                      </>
                    }
                  />
                </Col>
              </Row>
            </>
          )}
          {enterPriseAndTeams() && (
            <>
              <Row mt="26px">
                <Col xs>
                  <TemplateBlocked
                    blocked={permissions?.emailTypeCommon?.value}
                    header={
                      <Row>
                        <Col display="flex" alignItems="center">
                          <Label><FormattedMessage {...messages.personInCharge} /></Label>
                          <PlanIcon
                            featurePlan="enterprise"
                            ml={4}
                            width={270}
                          />
                        </Col>
                      </Row>
                    }
                    fields={
                      <>
                        <EmailVariableText
                          elementId="personInCharge"
                          intl={intl}
                          form="EmailNotifications"
                        />
                        <Field
                          id="personInCharge"
                          name="personInCharge"
                          component={TextArea}
                          placeholder={intl.formatMessage({...messages.personInChargePlaceholder})}
                          validate={[required, emptyString, inputLength]}
                          height="91px"
                          borderRadius="0 0 5px 5px"
                        />
                      </>
                    }
                  />
                </Col>
              </Row>
              <Row mt="26px">
                <Col xs>
                  <TemplateBlocked
                    blocked={permissions?.emailTypeCommon?.value}
                    header={
                      <Row>
                        <Col display="flex" alignItems="center">
                          <Label><FormattedMessage {...messages.contactInfo} /></Label>
                          <PlanIcon
                            featurePlan="enterprise"
                            ml={4}
                            width={270}
                          />
                        </Col>
                      </Row>
                    }
                    fields={
                      <>
                        <EmailVariableText
                          elementId="contactInformation"
                          intl={intl}
                          form="EmailNotifications"
                        />
                        <Field
                          id="contactInformation"
                          name="contactInformation"
                          component={TextArea}
                          placeholder={intl.formatMessage({...messages.contactInfoPlaceholder})}
                          validate={[required, emptyString, inputLength]}
                          height="91px"
                          borderRadius="0 0 5px 5px"
                        />
                      </>
                    }
                  />
                </Col>
              </Row>
            </>
          )}
          {enterPriseAndTeams() && (
            <PlanBlocked mt="26px" plan="premium">
              <Row mt="26px">
                <Col xs>
                  <TemplateBlocked
                    blocked={permissions?.emailTypeCommon?.value}
                    header={
                      <Row>
                        <Col display="flex" alignItems="center">
                          <Label mb="5px">{intl.formatMessage({...messages.logo})}</Label>
                          <PlanIcon
                            featurePlan="premium"
                            ml={-4}
                            width={160}
                          />
                        </Col>
                      </Row>
                    }
                    fields={
                      <div style={{ position: "relative", width: "250px", height: "100px", paddingTop: "12px" }}>
                        <Field
                          id="logo"
                          name="logo"
                          caption="ロゴを変更"
                          component={ImageUploader}
                          allowedFormats="image/*"
                          borderRadius="0%"
                          position="relative"
                          width="250px"
                          height="100px"
                          validate={[imageSize]}
                        />
                        {emailLogo && <ClearIcon className="material-icons" onClick={() => handleResetLogo()}>clear</ClearIcon>}
                      </div>
                    }
                  />
                </Col>
              </Row>  
            </PlanBlocked>
          )}
          {isStandardPlan() && (
            <>
              <Hr border="none" height="1px" color="#e5e5e5" my="20px" />
              <Button
                disabled={pristine || submitting || invalid}
                onClick={handleSubmit(values => onSubmit(values))}
                fontWeight="500"
              >
                <FormattedMessage {...messages.save} />
              </Button>
              <div style={{ height: "20px" }} />
            </>
          )}
        </Dashboard>
      </DesktopBreakpoint>
    </>
  );
}

export default reduxForm({
  form: 'EmailNotifications',
  touchOnChange: true,
})(injectIntl(EmailNotifications));
