import { getCurrentUser } from "./userUtils";

export const pushToGA = (event) => {
  const currentUser = getCurrentUser();
  const dataLayerArgs = { ...event };
  if(currentUser && currentUser.uqid) {
    dataLayerArgs["userId"] = currentUser.uqid;
    dataLayerArgs["userType"] = currentUser.admin ? "admin" : "employee";
    dataLayerArgs["provider"] = currentUser.provider;
    dataLayerArgs["employee_uid"] = currentUser.uqid;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerArgs);
};

export const events = {
  triggerPageView: (pagePath) => ({
    event: "page_view",
    page_path: pagePath, 
  }),
  create_bookingPage: () => ({
    event: "create_bookingPage"
  }),
  copy_bookingPage_url: (url_type, page) => ({
    event: "copy_bookingPage_url",
    url_type: url_type,
    page,
  }),
  change_bookingPage_duration: (duration) => ({
    event: "change_bookingPage_duration",
    duration: duration
  }),
  change_bookingPage_status: (status) => ({
    event: "change_bookingPage_status",
    status: status
  }),
  click_link_app: (href) => ({
    event: "click_link_app",
    href: href
  }),
  click_link_lp: (href) => ({
    event: "click_link_lp",
    href: href
  }),
  click_link_help: (href) => ({
    event: "click_link_help",
    href: href
  }),
  click_link_bp: (page) => ({
    event: "click_link_bp",
    page
  }),
  click_link_sp_billing: (page = "Dashboard") => ({
    event: "click_link_sp_billing",
    page
  }),
  click_link_dl_guide: () => ({
    event: "click_link_dl_guide",

  }),
  change_bookingPage_type: (page_type) => ({
    event: "change_bookingPage_type",
    page_type: page_type
  }),
  mouseover_featureTooltip: (feature, hover_time) => ({
    event: "mouseover_featureTooltip",
    feature: feature,
    hover_time: hover_time
  }),
  set_business_type: (type) => ({
    event: "set_business_type",
    type,
  }),
  leave_onboarding: (page) => ({
    event: "exit_onboarding",
    page
  })
};
