import React from "react";
import { camelizeKeys } from 'humps';
import moment from 'moment';
import humps from "lodash-humps";
import { FormattedMessage } from 'react-intl';
import messages from './messages/userUtilsMessages';

export const PLANS = {
  TRIAL: "trial",
  FREE: "free",
  STANDARD: "standard",
  ENTERPRISE: "enterprise",
  SUSPENDED: "suspended",
  PREMIUM: "premium",
};

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem('currentUser')) || {};
}

export function setCurrentUser(user) {
  localStorage.setItem("currentUser", JSON.stringify(user));
  return user;
}

export function getSubdomain() {
  const currentUser = getCurrentUser();

  return `${currentUser?.company?.custom_subdomain_webapp != null ? `${currentUser?.company?.custom_subdomain_webapp}.` : ''}`;
}

export function removeCurrentUser() {
  window.localStorage.removeItem("auth_headers");
  window.localStorage.removeItem("refresh_token");
  window.localStorage.removeItem("currentUser");
}

export function getPlan() {
  const currentUser = getCurrentUser();
  const product = (currentUser?.company?.products || []).filter(product => product.product_type === "scheduling")[0];

  if (currentUser == null || product == null) {
    return null;
  }

  return product?.configuration?.plan_status;
}

export function getScheduler() {
  const currentUser = getCurrentUser();
  const productScheduling = (currentUser?.company?.products || []).filter(product => product.product_type === "scheduling")[0];

  if (currentUser == null || productScheduling == null) {
    return null;
  }

  return productScheduling?.configuration?.scheduler;
};

function getProductScheduling() {
  const currentUser = getCurrentUser();
  return (currentUser?.company?.products || []).find(product => product.product_type === "scheduling");
}

export function isTemplatesEnabled() {
  const currentUser = getCurrentUser();
  const product = (currentUser?.company?.products || []).filter(product => product.product_type === "scheduling")[0];

  if (currentUser == null || product == null) {
    return null;
  }

  return product?.product_scheduling?.templates_enabled || false;
}

export function isTemplatesEnabledAndAdmin() {
  return isTemplatesEnabled() && isSchedulingAdmin();
}

export function isReceptionEnabled() {
  const currentUser = getCurrentUser();
  const product = (currentUser?.company?.products || []).filter(product => product.product_type === "reception")[0];

  if (currentUser == null || product == null) {
    return null;
  }

  return product.active || false;
}

export function isShowingBusinessCard() {
  const currentUser = getCurrentUser();

  return currentUser.company.employee_business_card && currentUser.business_card_link;
}

export function getPlanLabel() {
  const planStatus = getPlan();
  let label;

  switch(planStatus) {
    case PLANS.PREMIUM:
      label = <FormattedMessage {...messages.premium} />;
      break;
    case PLANS.ENTERPRISE:
      label = <FormattedMessage {...messages.enterprise} />;
      break;
    case PLANS.STANDARD:
      label = <FormattedMessage {...messages.standard} />;
      break;
    case PLANS.FREE:
      label = <FormattedMessage {...messages.free} />;
      break;
    case PLANS.TRIAL:
      const currentUser = humps(getCurrentUser());
      const productScheduling = (currentUser?.company?.products || []).find(product => product?.productType === 'scheduling');
      const trialExpireDate = Math.ceil(moment(productScheduling?.configuration?.trialExpiredAt).diff(moment(), 'days', true));
      // NOTE: 終了日が設定されている場合のみ、トライアル中である旨と終了日を表示する
      if (trialExpireDate) label = trialExpireDate > 0 ? <FormattedMessage {...messages.trialWithExpiredDate} values={{ days: trialExpireDate }} /> : "";
      break;
    default:
      break;
  }
  return label;
}

export function getBusinessType() {
  const currentUser = getCurrentUser();
  const product = (currentUser?.company?.products || []).filter(product => product.product_type === "scheduling")[0];

  if (currentUser == null || product == null) {
    return null;
  }

  return product?.configuration?.business_type;
}

export function isTrialExpired() {
  const currentUser = humps(getCurrentUser());
  const productScheduling = (currentUser?.company?.products || []).find(product => product?.productType === 'scheduling');
  const trialExpireDate = moment(productScheduling?.configuration?.trialExpiredAt).diff(moment(), 'days', true);

  return productScheduling?.productScheduling?.planStatus === PLANS.TRIAL && Math.ceil(trialExpireDate) >= -1;
}

export function getTrialExpireDate() {
  const currentUser = humps(getCurrentUser());
  const productScheduling = (currentUser?.company?.products || []).find(product => product?.productType === 'scheduling');
  const trialExpireDate = moment(productScheduling?.configuration?.trialExpiredAt).diff(moment(), 'days', true);

  return Math.ceil(trialExpireDate) || null;
}

export function isFreePlan() {
  return [PLANS.PREMIUM, PLANS.ENTERPRISE, PLANS.STANDARD, PLANS.FREE, PLANS.TRIAL].includes(getPlan());
}

export function isPaidOrTrialPlan() {
  return [PLANS.PREMIUM, PLANS.ENTERPRISE, PLANS.STANDARD, PLANS.TRIAL].includes(getPlan());
}

export function isStandardPlan() {
  return [PLANS.PREMIUM, PLANS.ENTERPRISE, PLANS.STANDARD, PLANS.TRIAL].includes(getPlan());
}

export function isEnterprisePlan() {
  return [PLANS.PREMIUM, PLANS.ENTERPRISE, PLANS.TRIAL].includes(getPlan());
}

export function isPremiumPlan() {
  return [PLANS.PREMIUM, PLANS.TRIAL].includes(getPlan());
}

export function isSchedulingAdmin() {
  return getCurrentUser()?.privilege_scheduling === "admin" || getCurrentUser()?.admin;
}

export function isProductSchedulingOrganization() {
  const currentUser = humps(getCurrentUser());
  const product = (currentUser?.company?.products || []).find(product => product?.productType === 'scheduling');
  return product?.configuration?.accessType === 'organization';
}

export function overrideCurrentUserCompany(newCompany) {
  const currentUser = getCurrentUser();

  currentUser.company = newCompany;
  setCurrentUser(currentUser);
}

export function generateContactPlanUrl() {
  const url = process.env.REACT_APP_SCHEDULING_LP_ROOT + '/contact-plan';
  const currentUser = camelizeKeys(getCurrentUser());

  if (currentUser && currentUser.company) {
    const {
      company,
      email,
    } = currentUser;
    const queries = `company_name=${company.name}&email=${email}`;

    return encodeURI(`${url}?${queries}`);
  }

  return url;
}

export function generateContactUrl() {
  return process.env.REACT_APP_SCHEDULING_LP_ROOT + '/contact';
}

export function generateHelpUrl(path = "/") {
  return process.env.REACT_APP_SCHEDULING_HELP_ROOT + path;
}

export function isGaroonIntegrated() {
  const currentUser = getCurrentUser();
  const product = (currentUser?.company?.products || []).filter(product => product.product_type === "scheduling")[0];
  return product?.configuration?.scheduler === "garoon" || currentUser?.garoon_user_name;
}

export function isBookingTypeIrregular() {
  const product = getProductScheduling();
  return product?.product_scheduling?.booking_type === "irregular";
}

export function isMultiGuestAllowed() {
  const allowedScheduler = ["google", "outlook"].includes(getScheduler());

  return allowedScheduler && !isBookingTypeIrregular() && isStandardPlan() && !isProductSchedulingOrganization();
}

export function isExportAppointmentsAllowed() {
  const product = getProductScheduling();
  return isSchedulingAdmin() || product?.product_scheduling?.allow_export_appointments_non_admins;
}

export function setReferralSource(source = null) {
  if(source) {
    return localStorage.setItem('referral', source);
  }
  localStorage.removeItem('referral');
}

export function getReferralSource() {
  const source = localStorage.getItem('referral');
  return source;
}

export function templatesEnabledAdminValidation() {
  const settings = window.location.pathname.split('/')[1];
  const isTemplatePage = [settings].includes('templates');

  if(isTemplatePage) {
    return isTemplatesEnabledAndAdmin();
  }

  return true;
}

export function setSamlCompanyEmailDomain(companyEmailDomain) {
  localStorage.setItem(
    'samlCompanyEmailDomain',
    JSON.stringify(companyEmailDomain),
  );
  return companyEmailDomain;
}

export function getSamlCompanyEmailDomain() {
  return JSON.parse(localStorage.getItem('samlCompanyEmailDomain')) || '';
}