export function calendarIntegratedUser(schedulers) {
  return (schedulers?.google || schedulers?.outlook || schedulers?.garoon)
}

export function zoomNotAllowedUser(schedulers, meetingType, webConfType) {
  return calendarIntegratedUser(schedulers) && (meetingType === "web_conference" && webConfType === "zoom" && !schedulers?.zoom)
}

export function webexNotAllowedUser(schedulers, meetingType, webConfType) {
  return calendarIntegratedUser(schedulers) && (meetingType === "web_conference" && webConfType === "webex" && !schedulers?.webex)
}

function useWebConference(authorizedSchedulers, webConfType) {
  if(webConfType === "zoom") return authorizedSchedulers?.zoom;
  return authorizedSchedulers?.webex;
}

function isAnyExternalIntegrated(members, meetingType, webConfType) {
  if(meetingType !== "web_conference" || !["zoom", "webex"].includes(webConfType)) return true;
  if(members.length === 0) return false;
  return members.some(member => (member.attendee || member.mustAttend) && calendarIntegratedUser(member.authorizedSchedulers) && useWebConference(member.authorizedSchedulers, webConfType));
}

function isAllExternalIntegrated(members, meetingType, webConfType) {
  if(meetingType !== "web_conference" || !["zoom", "webex"].includes(webConfType)) return true;
  if(members.length === 0) return false;
  return members.every(member => (member.attendee || member.mustAttend) && calendarIntegratedUser(member.authorizedSchedulers) && useWebConference(member.authorizedSchedulers, webConfType));
}

export function isExternalIntegrated(members, meetingType, webConfType, attendanceRule) {
  if(!members) return;
  if(attendanceRule === "custom_will_attend") {
    const mustMembers = members.filter(member => member.mustAttend);
    const optionalMembers = members.filter(member => !member.mustAttend && member.attendee);
    if(members.filter(member => member.mustAttend && member.name).length > 0) {
      return isAnyExternalIntegrated(mustMembers, meetingType, webConfType);
    }
    return isAnyExternalIntegrated(mustMembers, meetingType, webConfType) || isAllExternalIntegrated(optionalMembers, meetingType, webConfType);
  }
  if(attendanceRule === "all_will_attend") {
    return isAnyExternalIntegrated(members, meetingType, webConfType);
  }
  isAllExternalIntegrated(members, meetingType, webConfType);
}

export function getMustAttendeesCount(containers, attendanceRule) {
  if(attendanceRule === "custom_will_attend") {
    return containers[0].filter(member => member.mustAttend && member.name).length;
  }
  return 0;
}
