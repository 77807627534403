/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import arrayMove from "array-move"; // Lodash doesn't have this method: https://github.com/lodash/lodash/issues/1701
import { FormattedMessage, injectIntl } from 'react-intl';
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  reduxForm,
  Field,
  formValueSelector,
} from "redux-form";
import { cloneDeep, find, sortBy, isEqual } from "lodash";
import {
  Button,
  Label,
  Col,
  Hr,
  Row,
  Loader,
  IconNew as Icon,
  Checkbox,
  DesktopBreakpoint,
  PhoneBreakpoint,
} from "@d-lighted/design-system";
import RestrictContainerBox from 'components/RestrictContainerBox';
import fieldImage from "assets/img/ico_shorttext.svg";
import { Dashboard } from "components/layouts/Dashboard";
import { actions } from "redux/actions";
import { useGet, post, put, remove } from "utils/api";
import { isPaidOrTrialPlan, generateContactPlanUrl, isBookingTypeIrregular } from "utils/userUtils";
import { NoSupportWarning } from "components/layouts/NoSupportWarning";
import { FieldImageWrapper, FieldImage, FieldName } from "components/elements/BorderedField";
import TemplatePermissionSelector, {
  convertToOption,
  convertToPermission
} from "components/forms/TemplatePermissionSelector";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import CheckboxIcon from "components/elements/CheckboxIcon";
import { SortableList } from "../modules/InputScreenSortableContainer";
import ConfirmationDialogue from "../modules/ConfirmationDialogue";
import RequiredMark from '../modules/RequiredMark';
import { formatTextFieldObject } from "../utils/constants";
import { restrictionOptions } from "../utils/options";
import messages from '../i18n/InputScreenSetting';

const fieldSelector = formValueSelector("InputScreenSettings");

const AllowMultiguestCheck = styled.span`
  font-size: 15px;
  margin-left: 6px;
  color: #314143;
  font-weight: 400;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;

const HideableRow = styled(Row)`
  & > ${Col} * {
    opacity: ${({ hidden, isBlocked }) => {
  if (isBlocked && hidden) return 0.2;
  if (isBlocked && !hidden) return 0.6;
  if (!isBlocked && hidden) return 0.3;

  return 1;
}};
  ${({ isBlocked }) => isBlocked && `
    pointer-events: none;
    user-select: none;
  `}
  }

  & > ${Col} ${Icon} {
    opacity: 1 !important;
    pointer-events: all;
    user-select: none;
  }
`;

const NormalRow = styled(Row)`
  & > ${Col} * {
    opacity: ${({ isBlocked }) => isBlocked ? 0.6 : 1};
    ${({ isBlocked }) => isBlocked && `
      pointer-events: none;
      user-select: none;
    `}
  }
`;

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

function InputScreenSettings(props) {
  const { useNotify, intl, change, initialize, pristine } = props;
  const { uid, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const { setTemplateUid, saveTemplateSettings } = useSavedTemplateSettings();
  const notify = useNotify(intl);
  const isIrregular = isBookingTypeIrregular();
  const dispatch = useDispatch();

  const [isCreating, setIsCreating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [field, setField] = useState({});
  const [defaultMultiguestAllowed, setDefaultMultiguestAllowed] = useState(null);
  const [defaultCustomFields, setDefaultCustomFields] = useState([]);

  useGet(calendarSettingRequest(uid));

  const { isSuccess, data } = useSelector((state) => state.calendarSetting);

  /** Reading Redux-form */
  const fields = useSelector((state) => fieldSelector(state, "fields") || []);
  const companyNameFieldIsRequired = useSelector((state) => fieldSelector(state, "companyNameFieldIsRequired"));
  const guestNameFieldIsRequired = useSelector((state) => fieldSelector(state, "guestNameFieldIsRequired"));
  const guestNameFieldIsHidden = useSelector((state) => fieldSelector(state, "guestNameFieldIsHidden"));
  const companyNameFieldIsHidden = useSelector((state) => fieldSelector(state, "companyNameFieldIsHidden"));
  const permissions = useSelector(state => fieldSelector(state, "permissions"));

  // eslint-disable-next-line
  useEffect(() => {
    if (isSuccess) {
      const {
        companyNameFieldIsRequired,
        companyNameFieldIsHidden,
        multipleguestAllowed,
        bookingCalendarTemplateUid,
        permissions,
        guestNameFieldIsRequired,
        guestNameFieldIsHidden,
      } = data;
      let customFields = cloneDeep(data?.bookingCalendarCustomFields);
      sortBy(customFields, [function(field) { return field.rowOrder }]);
      setDefaultCustomFields(customFields);
      setTemplateUid(bookingCalendarTemplateUid);
      setDefaultMultiguestAllowed(multipleguestAllowed);
      initialize({
        fields: customFields,
        companyNameFieldIsRequired: companyNameFieldIsRequired,
        companyNameFieldIsHidden: companyNameFieldIsHidden,
        multipleguestAllowed: multipleguestAllowed,
        permissions: convertToOption(permissions, intl),
        guestNameFieldIsRequired: guestNameFieldIsRequired,
        guestNameFieldIsHidden: guestNameFieldIsHidden,
      });
    }
  // eslint-disable-next-line
  }, [data, isSuccess]);

  const onSortEnd = ( oldIndex, newIndex ) => {
    return change("fields", arrayMove([...fields], oldIndex, newIndex));
  };

  /** Function to handle Edit Field */
  const handleEditField = (e, field) => {
    e.preventDefault();
    props.history.push({
      pathname: `/${settings}/${uid}/edit/booking_form/${field.uid}/booking_form_settings`,
    });
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  /** Function to delete fields */
  const handleDeleteField = (e, field) => {
    e.preventDefault();
    setField(field);
    toggleModal();
  };

  const confirmFn = async () => {
    setIsDeleting(true);
    const url = `/booking_calendar_settings/${uid}/booking_calendar_custom_fields/${field.uid}`;
    const result = await remove(url, process.env.REACT_APP_API_ENDPOINT);
    setIsDeleting(false);
    toggleModal();
    processResult(result, null,false);
    if (result.status >= 200 && result.status < 300) {
      change("fields", [
        ...fields.filter((item) => item.uid !== field.uid),
      ]);
    }
    await saveTemplateSettings(convertToOption(data.permissions, intl), { data: {} });
  };

  function processResult(result, values, throwError = true) {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.saveError, result?.data?.error?.message);
    } else {
      if (values != null) {
        dispatch({
          type: 'calendarSetting_update',
          payload: { 
            data: { 
              ...data,
              bookingCalendarCustomFields: values.fields,
              permissions: convertToPermission(values.permissions, false),
              companyNameFieldIsRequired: values.companyNameFieldIsRequired,
              companyNameFieldIsHidden: values.companyNameFieldIsHidden,
              multipleguestAllowed: values.multipleguestAllowed,
            }
          },
        });
      }
      notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.saveSuccess);
      return result;
    }
  }

  // Pragmatic navigation to add another field
  const createCustomField = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    const url = `/booking_calendar_settings/${uid}/booking_calendar_custom_fields`;
    const body = {
      booking_calendar_setting_uid: uid,
    };
    const result = await post(url, body, process.env.REACT_APP_API_ENDPOINT);
    processResult(result, null, false);
    if (result.status < 200 || result.status >= 300) {
      setIsCreating(false);
      return;
    }
    const _data = result.data.booking_calendar_custom_field;
    const value = formatTextFieldObject({
      uid: _data.uid,
      fieldType: _data.field_type,
      labelName: _data.label_name,
      inputRequired: false,
      textFieldInputRestriction: find(restrictionOptions, { value: 0 }),
    });
    change("fields", [...fields, value]);
    await saveTemplateSettings(convertToOption(data.permissions, intl), { data: {} });
    setIsCreating(false);
  };

  const updateCustomFields = async (values) => {
    const url = `/booking_calendar_settings/${uid}/sort_custom_field`;

    const body = {
      booking_calendar_custom_field: {
        sort_custom_fields: values.fields.map((value, index) => ({
          booking_calendar_custom_field_uid: value.uid,
          row_order_position: index
        }))
      },
      booking_calendar_setting_uid: uid
    };
    return put(url, body);
  }

  const updateSettings = (values) => {
    const url = `/booking_calendar_settings/${uid}`;

    const body = {
      multipleguest_allowed: values.multipleguestAllowed
    };

    return put(url, body);
  }

  const handleClickSaveButton = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

// Function to handle Submit
  const handleOnSubmit = (values) => {
    const calls = [];
    if (!isEqual(defaultCustomFields, values.fields)) {
      calls.push(updateCustomFields(values));
      setDefaultCustomFields(values.fields);
    }

    if (defaultMultiguestAllowed !== values.multipleguestAllowed) {
      calls.push(updateSettings(values));
      setDefaultMultiguestAllowed(values.multipleguestAllowed);
    }
    
    Promise.all(calls).then(async resultArray => {
      let resultFromAll = null;
      if (resultArray.length > 0) {
        resultFromAll = resultArray.reduce((accumulation, value) => {
          if (accumulation.status >= 200) return accumulation;
          if (value.status >= 200) return value;

          return accumulation;
        })
      }

      const result = await saveTemplateSettings(values.permissions, resultFromAll);
      if(result?.status) {
        processResult(result, values);
      }
    })
  };

  if (!isSuccess) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  
  const isBlocked = !isTemplatePage && permissions?.fields?.value === "administrator";

  return (
    <>
      <PhoneBreakpoint>
        <NoSupportWarning/>
      </PhoneBreakpoint>
      <DesktopBreakpoint>
        <Dashboard>
          <RestrictContainerBox
            isAvailable={isPaidOrTrialPlan()}
            guideText={
              <FormattedMessage
                {...messages.standard}
                values={{
                  br: <br/>,
                  a: chunks => (
                    <a target="_blank" rel="noopener noreferrer" href={generateContactPlanUrl()}>
                      {chunks}
                    </a>
                  )
                }}
              />
            }
          >
            <Field name="fields" hidden component="input" />
            <Row mt="40px">
              <Col xs>
                <TemplateBlocked
                  blocked={permissions?.fields?.value}
                  unlockedFieldsBlocking={!isTemplatePage}
                  header={
                    <Row>
                      <Col xs>
                        <Label>
                          <FormattedMessage {...messages.customFields} />
                        </Label>
                      </Col>
                      {isTemplatePage && (
                        <Col>
                          <TemplatePermissionSelector name="fields"/>
                        </Col>
                      )}
                    </Row>
                  }
                  fields={
                    <>
                      <HideableRow mt="35px" ml="35px" hidden={guestNameFieldIsHidden} isBlocked={isBlocked}>
                        <Col xs display="flex" alignItems="center">
                          <FieldImageWrapper>
                            <FieldImage src={fieldImage} />
                          </FieldImageWrapper>
                          <RequiredMark required={guestNameFieldIsRequired} />
                          <FieldName>
                            <FormattedMessage {...messages.nameField} />
                          </FieldName>
                          <Icon
                            isCursor
                            onClick={e => handleEditField(e, {uid: 'guest_name_field'})}
                            className="material-icons"
                            color="#00bbb5"
                            fontSize="23px"
                            mr="5px"
                          >
                            edit
                          </Icon>
                        </Col>
                      </HideableRow>
                      <HideableRow mt="35px" ml="35px" hidden={companyNameFieldIsHidden} isBlocked={isBlocked}>
                        <Col xs display="flex" alignItems="center">
                          <FieldImageWrapper>
                            <FieldImage src={fieldImage} />
                          </FieldImageWrapper>
                          <RequiredMark required={companyNameFieldIsRequired}/>
                          <FieldName>
                            <FormattedMessage {...messages.companyNameField} />
                          </FieldName>
                          <Icon
                            isCursor
                            onClick={e => handleEditField(e, {uid: 'company_name_field'})}
                            className="material-icons"
                            color="#00bbb5"
                            fontSize="23px"
                            mr="5px"
                          >
                            edit
                          </Icon>
                        </Col>
                      </HideableRow>
                      <NormalRow mt="35px" ml="35px" mb={isIrregular ? "35px" : "0"} isBlocked={isBlocked}>
                        <Col xs display="flex" alignItems="center">
                          <FieldImageWrapper >
                            <FieldImage src={fieldImage} />
                          </FieldImageWrapper>
                          <RequiredMark />
                          <FieldName >
                            <FormattedMessage {...messages.emailField} />
                          </FieldName>
                        </Col>
                      </NormalRow>
                      {!isIrregular && (
                        <NormalRow 
                          mt="35px" 
                          ml="35px"
                          isBlocked={isBlocked}
                          ref={node => node && (isBlocked ? node.setAttribute('inert', '') : node.removeAttribute('inert'))}
                        >
                          <Col xs display="flex" alignItems="center" pl="14px" >
                            <Field
                              id="multipleguestAllowed"
                              name="multipleguestAllowed"
                              component={Checkbox}
                              useArrayValue={false}
                            >
                              <AllowMultiguestCheck>
                                <FormattedMessage {...messages.multipleguestAllowed} />
                              </AllowMultiguestCheck>
                            </Field>
                          </Col>
                        </NormalRow>
                      )}
                      <SortableList
                        fields={fields}
                        onSortEnd={onSortEnd}
                        lockAxis="y"
                        handleEditField={handleEditField}
                        handleDeleteField={handleDeleteField}
                        isBlocked={isBlocked}
                        useDragHandle
                      />
                      <NormalRow 
                        mt="20px"
                        isBlocked={isBlocked}
                        ref={node => node && (isBlocked ? node.setAttribute('inert', '') : node.removeAttribute('inert'))}
                      >
                        <Col xs>
                          <Button
                            outlined
                            height="32px"
                            fontWeight="normal"
                            fontSize="14px"
                            disabled={isCreating}
                            onClick={createCustomField}
                          >
                            <FormattedMessage {...messages.addCustomField} />
                          </Button>
                        </Col>
                      </NormalRow>
                      <PlanBlocked mt="26px" plan="enterprise">
                        <HideableRow 
                          mt="35px"
                          ml="35px"
                          isBlocked={isBlocked}
                        >
                          <Col xs display="flex" alignItems="center">
                            <FieldImageWrapper>
                              <CheckboxIcon />
                            </FieldImageWrapper>
                            <FieldName left="17px">
                              <FormattedMessage {...messages.privacyHeadLabel} />
                            </FieldName>
                            <Icon
                              isCursor
                              onClick={e => handleEditField(e, {uid: 'privacy_policy_field'})}
                              className="material-icons"
                              color="#00bbb5"
                              fontSize="23px"
                              mr="5px"
                            >
                              edit
                            </Icon>
                          </Col>
                        </HideableRow>
                      </PlanBlocked>
                    </>
                  }
                />
              </Col>
            </Row>
            <Hr border="none" height="1px" color="#e5e5e5" mt="20px" />
            <Row mt="60px">
              <Col xs>
                <Button
                  disabled={(isEqual(defaultCustomFields, fields) && pristine) || isCreating}
                  onClick={props.handleSubmit(handleClickSaveButton)}
                >
                  <FormattedMessage {...messages.save} />
                </Button>
              </Col>
            </Row>
            <ConfirmationDialogue
              isOpen={isOpen}
              toggleModal={toggleModal}
              isDeleting={isDeleting}
              confirmFn={confirmFn}
            />
          </RestrictContainerBox>
        </Dashboard>
      </DesktopBreakpoint>
    </>
  );
}

InputScreenSettings.propTypes = {};

export default reduxForm({
  form: "InputScreenSettings",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
})(injectIntl(InputScreenSettings));
