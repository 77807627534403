/* eslint-disable  react-hooks/exhaustive-deps*/
import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import { reduxForm, Field, SubmissionError, formValueSelector } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { createAction } from "@reduxjs/toolkit";
import {
  Button,
  Checkbox,
  Label,
  RadioButton,
  InputWithInlineLabel,
  Col,
  Hr,
  Row,
  PhoneBreakpoint,
  DesktopBreakpoint
} from "@d-lighted/design-system";
import { FormattedMessage, injectIntl } from 'react-intl';

import { put, useGet } from "utils/api";
import { actions } from "redux/actions";
import { apiUtils } from "utils/apiUtils";
import { Dashboard } from "components/layouts/Dashboard";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import { useValidation } from 'utils/validation';
import * as messageIdConstants from "components/layouts/constants/messageIdConstants";
import { NoSupportWarning } from "components/layouts/NoSupportWarning";
import TemplatePermissionSelector, { convertToOption } from "components/forms/TemplatePermissionSelector";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import * as CHAT_CONSTANTS from "../constants/chatNameConstants";
import * as IMAGE_CONSTANTS from "../constants/chatAppImageConstants";
import messages from '../i18n/ChatNotifications';

const Logo = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 5px;
  position: relative;
  top: 7px;
`;

const NotifyOnSlackDM = styled.div`
  width: 345px;
  height: 69px;
  font-family: NotoSansJP;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-primary);
`

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

const ICONS = {
  [CHAT_CONSTANTS.SLACK]: IMAGE_CONSTANTS.SLACK,
  [CHAT_CONSTANTS.CHATWORK]: IMAGE_CONSTANTS.CHATWORK,
  [CHAT_CONSTANTS.ONETEAM]: IMAGE_CONSTANTS.ONETEAM,
  [CHAT_CONSTANTS.FACEBOOK_WORKPLACE]: IMAGE_CONSTANTS.FACEBOOK_WORKPLACE,
  [CHAT_CONSTANTS.MICROSOFT_TEAMS]: IMAGE_CONSTANTS.MICROSOFT_TEAMS,
  [CHAT_CONSTANTS.LINE_WORKS]: IMAGE_CONSTANTS.LINE_WORKS,
  [CHAT_CONSTANTS.GOOGLE_CHAT]: IMAGE_CONSTANTS.GOOGLE_CHAT,
  [CHAT_CONSTANTS.DINGTALK]: IMAGE_CONSTANTS.DINGTALK,
  [CHAT_CONSTANTS.SMS]: IMAGE_CONSTANTS.SMS,
  [CHAT_CONSTANTS.INCIRCLE]: IMAGE_CONSTANTS.INCIRCLE,
  [CHAT_CONSTANTS.MICROSOFT_TEAMS_OAUTH]: IMAGE_CONSTANTS.MICROSOFT_TEAMS,
};

const ChatNotificationsForm = formValueSelector("ChatNotifications");

const ChatNotifications = (props) => {
  const { useNotify, intl, initialize, history, pristine, submitting } = props;
  const { uid, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const { setTemplateUid, saveWithTemplateSettings } = useSavedTemplateSettings();
  useGet(actions.getChatSetting);
  useGet(calendarSettingRequest(uid));
  const { required, emptyString } = useValidation(intl);
  const dispatch = useDispatch();
  const updateCalendarSetting = createAction(
    `${actions.getCalendarSetting.name}_update`
  );
  const { data, isSuccess, hasError } = useSelector(state => state.calendarSetting);
  const selectedChat = useSelector(state => ChatNotificationsForm(state, "notifyChat"));
  const notifySlackShouldDm = useSelector(state => ChatNotificationsForm(state, "notifySlackShouldDm")) || false;
  const permissions = useSelector(state => ChatNotificationsForm(state, "permissions"));
  const { data: chatsData, isSuccess: isChatSuccess } = useSelector(state => state.chatSetting);
  const [enabledChats, setEnabledChats] = useState([]);
  const notify = useNotify(intl);

  useEffect(() => {
    if (isSuccess) {
      const {
        notifyChat,
        notifyConfirmation,
        notifyMentionIn,
        notifyMentionInMicrosoftTeam,
        notifySlackShouldDm,
        bookingCalendarTemplateUid,
        permissions,
      } = data;
      setTemplateUid(bookingCalendarTemplateUid);
      initialize({
        notifyChat: notifyChat?.uid,
        notifyConfirmation,
        notifyMentionIn,
        notifyMentionInMicrosoftTeam,
        notifySlackShouldDm,
        permissions: convertToOption(permissions, intl),
      });
    }
    if(hasError) {
      dispatch({
        type: "calendarSetting_get",
        payload: {status: null, data: null, isSuccess: false, hasError: false, initial: true}
      });
      history.push('/')
    }
  }, [data]);
  
  useEffect(() => {
    if(isChatSuccess) {
      const allowedTools = data.pageType ? [CHAT_CONSTANTS.SLACK, CHAT_CONSTANTS.CHATWORK, CHAT_CONSTANTS.MICROSOFT_TEAMS_OAUTH, CHAT_CONSTANTS.GOOGLE_CHAT] : [CHAT_CONSTANTS.SLACK];
      setEnabledChats((chatsData?.settingChats || [])
        .filter((chat) => chat.active && allowedTools.includes(chat.chat?.name))
        .map((chat) => {
          return { name: chat.chat?.name, icon: ICONS[chat.chat?.name], uid: chat.chat?.uid }
      }));
    }
  }, [chatsData]);

  if (!isSuccess) {
    return <Redirect to="/" />;
  }

  function formatBody(values) {
    return {
      notify_chat: values.notifyChat,
      notify_confirmation: values.notifyConfirmation,
      notify_mention_in: values.notifyMentionIn,
      notify_mention_in_microsoft_team: values.notifyMentionInMicrosoftTeam,
      notify_slack_should_dm: values.notifySlackShouldDm
    };
  }

  function processResult(result) {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.notifyError, result?.data?.message);
      throw new SubmissionError({ _error: "Invalid Data" });
    } else {
      notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
      dispatch(updateCalendarSetting(apiUtils.formatAxiosResponse(result)));
      return result;
    }
  }

  function getChatNameByUid(uid){
    let chat = enabledChats.filter((chat) => chat.uid === uid )[0];
    return chat?.name
  }

  function setLabel(selectedChat){
    let chat = getChatNameByUid(selectedChat);
    switch (chat) {
      case CHAT_CONSTANTS.CHATWORK:
        return <FormattedMessage {...messages.chatworkGroupId} />;
      case CHAT_CONSTANTS.GOOGLE_CHAT:
        return <FormattedMessage {...messages.googleChatRoomWebhook} />;
      case CHAT_CONSTANTS.MICROSOFT_TEAMS_OAUTH:
        return <FormattedMessage {...messages.microsoftTeamsChannelUrl} />;
      default:
        return <FormattedMessage {...messages.slackChannel} />;
    }
  }

  function setPlaceholder(selectedChat){
    let chat = getChatNameByUid(selectedChat);
    switch (chat) {
      case "Chatwork":
        return "123456789";
      case "Slack":
        return "channel";
      default:
       return "URL";
    }
  }

  const onSubmit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

  const handleOnSubmit = async (values) => {
    const url = `/booking_calendar_settings/${uid}`;
    const body = formatBody(values);

    const result = isTemplatePage ? await saveWithTemplateSettings(put(url,body), values.permissions) : await put(url, body);

    return processResult(result);
  };
  
  const accessBlockedMessage = () => {
    if(enabledChats.length === 0) {
      if(data.pageType) {
        // when teams
        return messageIdConstants.chatToolsNotLinked;
      }
      return messageIdConstants.slackOnlyAllowed;
    }
    return null;
  }

  return (
    <>
      <PhoneBreakpoint>
        <NoSupportWarning />
      </PhoneBreakpoint>
      <DesktopBreakpoint>
        <Dashboard>
          <PlanBlocked mt="26px" plan="standard" messageId={accessBlockedMessage()}>
              <form>
                <Row mt="40px" mb="26px">
                  <Col xs>
                    <TemplateBlocked
                      blocked={permissions?.chatNotification?.value}
                      header={
                        <Row mb="10px">
                          <Col xs>
                            <Label><FormattedMessage {...messages.timing} /></Label>
                          </Col>
                          {isTemplatePage && (
                            <Col>
                              <TemplatePermissionSelector name="chatNotification"/>
                            </Col>
                          )}
                        </Row>
                      }
                      fields={
                        <Row mt="12px">
                          <Col xs height="35px">
                            <Field
                              id="notifyConfirmation"
                              name="notifyConfirmation"
                              useArrayValue={false}
                              component={Checkbox}
                            >
                              <FormattedMessage {...messages.timingConfirmation} />
                            </Field>
                          </Col>
                        </Row>
                      }
                    />
                  </Col>
                </Row>
                <Row mt="40px" mb="26px">
                  <Col xs>
                    <TemplateBlocked
                      blocked={permissions?.chatNotification?.value}
                      header={
                        <Row mb="10px">
                          <Col xs>
                            <Label>
                              <FormattedMessage {...messages.chats} />
                            </Label>
                          </Col>
                        </Row>
                      }
                      fields={
                        <>
                          {enabledChats.map(chat => (
                            <Row key={chat.uid}>
                              <Col xs>
                                <Field
                                  id="notifyChat"
                                  name="notifyChat"
                                  trueFor={chat.uid}
                                  component={RadioButton}
                                  validate={[required]}
                                >
                                  <Logo src={chat.icon} alt={chat.name} /> {chat.name}
                                </Field>
                              </Col>
                            </Row>
                          ))}
                        </>
                      }
                    />
                  </Col>
                </Row>
                { getChatNameByUid(selectedChat) === CHAT_CONSTANTS.MICROSOFT_TEAMS_OAUTH &&
                  <Row mb="26px">
                    <Col xs>
                      <TemplateBlocked
                        blocked={permissions?.chatNotification?.value}
                        header={
                          <Row mb="10px">
                            <Col xs>
                              <Label>
                                <FormattedMessage {...messages.microsoftTeamsTeamUrl} />
                              </Label>
                            </Col>
                          </Row>
                        }
                        fields={
                          <Row>
                            <Col xs>
                              <Field
                                name="notifyMentionInMicrosoftTeam"
                                component={InputWithInlineLabel}
                                label="#"
                                validate={[required, emptyString]}
                                placeholder={setPlaceholder(selectedChat)}
                                width="90%"
                              />
                            </Col>
                          </Row>
                        }
                      />
                      
                      
                    </Col>
                  </Row>
                  }

                <Row mb="26px">
                  <Col xs>
                    <TemplateBlocked
                      blocked={permissions?.chatNotification?.value}
                      header={
                        <Row mb="10px">
                          <Col xs>
                            <Label>
                              {
                                setLabel(selectedChat)
                              }
                            </Label>
                          </Col>
                        </Row>
                      }
                      fields={
                        <>
                          { getChatNameByUid(selectedChat) === CHAT_CONSTANTS.SLACK && data.pageType &&
                          (<>
                            <Row>
                              <Col xs>
                                <Field
                                  id="notifySlackShouldDm"
                                  name="notifySlackShouldDm"
                                  component={RadioButton}
                                  trueFor={true}
                                  width="90%"
                                >
                                  <FormattedMessage {...messages.slackByDM} />
                                </Field>
                              </Col>
                            </Row>
                            <Row mb="13px">
                              <Col xs>
                                <Field
                                  id="notifySlackShouldDm"
                                  name="notifySlackShouldDm"
                                  component={RadioButton}
                                  trueFor={false}
                                  width="90%"
                                >
                                  <FormattedMessage {...messages.slackByChannel} />
                                </Field>
                              </Col>
                            </Row>
                          </>)
                          }
                          {
                            (getChatNameByUid(selectedChat) !== CHAT_CONSTANTS.SLACK ||
                              (getChatNameByUid(selectedChat) === CHAT_CONSTANTS.SLACK && !notifySlackShouldDm)) && data.pageType &&
                            (
                              <Row>
                                <Col xs>
                                  <Field
                                    name="notifyMentionIn"
                                    component={InputWithInlineLabel}
                                    label={getChatNameByUid(selectedChat) === CHAT_CONSTANTS.CHATWORK ? "https://www.chatwork.com/#!rid" : "#"}
                                    validate={[required, emptyString]}
                                    placeholder={setPlaceholder(selectedChat)}
                                    width="90%"
                                  />
                                </Col>
                              </Row>
                            )
                          }
                          {
                            enabledChats.some(chat => chat.name === CHAT_CONSTANTS.SLACK) && !data.pageType &&
                            <NotifyOnSlackDM>
                              <FormattedMessage {...messages.slackByDMOnlyAllowed} values={{br: <br />}}/>
                            </NotifyOnSlackDM>
                          }
                        </>
                      }
                    />
                  </Col>
                </Row>
                <Hr border="none" height="1px" color="#e5e5e5" my="20px" />
                <Button
                  disabled={pristine || submitting}
                  onClick={props.handleSubmit((values) => onSubmit(values))}
                  fontWeight="500"
                >
                  <FormattedMessage {...messages.save} />
                </Button>
                <div style={{ height: "20px" }} />
              </form>
          </PlanBlocked>
        </Dashboard>
      </DesktopBreakpoint>
    </>
  );
};

ChatNotifications.propTypes = {};

export default reduxForm({
  form: "ChatNotifications"
})(injectIntl(ChatNotifications));
