/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { reduxForm, Field, FieldArray, SubmissionError, formValueSelector } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";

import {
  Button,
  InputSelect,
  Label,
  Col,
  Hr,
  Row,
  Checkbox,
  DefaultSelect,
  DesktopBreakpoint,
  PhoneBreakpoint,
  RadioButton,
} from "@d-lighted/design-system";
import { Dashboard } from "components/layouts/Dashboard";
import Tooltip from "components/elements/Tooltip";
import { put, useGet } from "utils/api";
import { actions } from "redux/actions";
import { apiUtils } from "utils/apiUtils";
import { useLocale } from "utils/localeUtils";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import { pushToGA, events } from "utils/gtmUtils";
import RenderPrefixes from "components/forms/AvailableEventPrefixCreation/components/RenderPrefixes";
import { useValidation } from "utils/validation";
import { generateHelpUrl } from "utils/userUtils";
import { MOBILEBREAKPOINT } from "constants/breakpoint";
import TemplatePermissionSelector, { convertToOption } from "components/forms/TemplatePermissionSelector";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import PlanIcon from '../../../components/elements/PlanIcon';
import messages from "../i18n/Availability";

const InlineWrapper = styled.div`
  display: inline-block;
  margin-right: ${(props) => (props.mr ? props.mr : "0")}px;
  margin-left: ${(props) => (props.ml ? props.ml : "0")}px;
  color: #314143;
  font-weight: 200;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    line-height: 1.4;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 22px;
  }
`;

const LabelWithMargin = styled(Label)`
  margin-right: 8px;
`;

const StyledCol = styled(Col)`
  margin-left: -21px;
  @media (min-width: 1368px) and (max-width: 1648px){
    margin-left: -4px;
    padding-right: 0.1rem;
    padding-left: 0.1rem;
  }
  @media (min-width: 1024px) and (max-width: 1368px){
    margin-left: 0px;
  }
`;

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

const AdvancedSettingsForm = formValueSelector("AdvancedSettings");

function AdvancedSettings(props) {
  const { uid, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const { setTemplateUid, saveWithTemplateSettings } = useSavedTemplateSettings();
  useGet(calendarSettingRequest(uid));
  const { data, isSuccess, hasError } = useSelector(
    (state) => state.calendarSetting
  );
  const [locale] = useLocale();
  const { initialize, history, useNotify, intl, pristine, submitting } = props;
  const dispatch = useDispatch();
  const { availableEventPrefixesLength } = useValidation(intl);
  const notify = useNotify(intl);
  const [preBufferEventEnabled, setPreBufferEventState] = useState(false);
  const [postBufferEventEnabled, setPostBufferEventState] = useState(false);
  const [addingAvailableEventPrefix, setAddingAvailableEventPrefix] = useState(
    false
  );
  const helpUrl = generateHelpUrl();

  const permissions = useSelector(state => AdvancedSettingsForm(state, "permissions"));

  const appointmentCancellationState = useSelector(
    state => state.form.AdvancedSettings?.values.allowAppointmentCancellation
  );

  useEffect(() => {
    if (isSuccess) {
      const { preBufferCalEvent, postBufferCalEvent, bookingCalendarTemplateUid, allowAppointmentCancellation, permissions } = data;
      const preBufferCalEventDuration = {
        value: data.preBufferCalEventDuration,
        label: `${
          data.preBufferCalEventDuration || 0
        }${intl.formatMessage({ ...messages.timeMinutes })}`,
      };
      const postBufferCalEventDuration = {
        value: data.postBufferCalEventDuration,
        label: `${
          data.postBufferCalEventDuration || 0
        }${intl.formatMessage({ ...messages.timeMinutes })}`,
      };
      const buffer = {
        value: data.buffer,
        label: `${data.buffer}${intl.formatMessage({
          ...messages.timeMinutes,
        })}`,
      };
      const appointmentCancellationTimeLimit = {
        value: data.appointmentCancellationTimeLimit || 0,
        label: `${
          data.appointmentCancellationTimeLimit || 0
        }${props.intl.formatMessage({ ...messages.timeMinutes })}`,
      };
      const availableEventPrefixes = data.availableEventPrefixes;
      setTemplateUid(bookingCalendarTemplateUid);
      initialize({
        buffer,
        availableEventPrefixes,
        preBufferCalEvent,
        postBufferCalEvent,
        preBufferCalEventDuration,
        postBufferCalEventDuration,
        permissions: convertToOption(permissions, intl),
        allowAppointmentCancellation,
        appointmentCancellationTimeLimit,
      });
      setPreBufferEventState(preBufferCalEvent);
      setPostBufferEventState(postBufferCalEvent);
    }
    if (hasError) {
      dispatch({
        type: "calendarSetting_get",
        payload: {
          status: null,
          data: null,
          isSuccess: false,
          hasError: false,
          initial: true,
        },
      });
      history.push("/");
    }
  }, [data, locale]);

  if (!isSuccess) {
    return <Redirect to="/" />;
  }

  function getBody(values) {
    return {
      buffer: values.buffer.value,
      available_event_prefixes: values.availableEventPrefixes,
      pre_buffer_cal_event: values.preBufferCalEvent,
      post_buffer_cal_event: values.postBufferCalEvent,
      pre_buffer_cal_event_duration: values.preBufferCalEventDuration.value,
      post_buffer_cal_event_duration: values.postBufferCalEventDuration.value,
      allow_appointment_cancellation: values.allowAppointmentCancellation,
      appointment_cancellation_time_limit: values.appointmentCancellationTimeLimit.value,
    };
  }

  function processResult(result) {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.notifyError, result?.data?.message);;
      throw new SubmissionError({ _error: "Error Occur" });
    } else {
      notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
      dispatch({
        type: `${actions.getCalendarSetting.name}_update`,
        payload: { ...apiUtils.formatAxiosResponse(result) },
      });
      return result;
    }
  }

  const onSubmit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

  const handleOnSubmit = async (values) => {
    const url = `/booking_calendar_settings/${uid}`;
    const body = getBody(values);
    const result = isTemplatePage ? await saveWithTemplateSettings(put(url,body), values.permissions) : await put(url, body);

    return processResult(result);
  };

  const tooltip = props.intl.formatMessage({ ...messages.bufferTooltip });

  const options = [];
  [0, 15, 30, 60, 90].forEach((i) => {
    options.push({
      value: i,
      label: `${i}${props.intl.formatMessage({ ...messages.timeMinutes })}`,
    });
  });

  const bufferCalDurations = [];
  [15, 30, 60, 90].forEach((i) => {
    bufferCalDurations.push({
      value: i,
      label: `${i}${props.intl.formatMessage({ ...messages.timeMinutes })}`,
    });
  });

  const handleBufferCalStateChange = (allowed, pos) => {
    if (pos === "pre") {
      return setPreBufferEventState(allowed);
    }
    return setPostBufferEventState(allowed);
  };

  return (
    <Dashboard>
      <Row mt={["26px", "40px"]} mb="26px">
        <Col xs>
          <TemplateBlocked
            blocked={permissions?.buffer?.value}
            header={
              <Row mb={["18px", "10px"]}>
                <Col xs display="flex" alignItems="center">
                  <LabelWithMargin>
                    <FormattedMessage {...messages.buffer} />
                  </LabelWithMargin>
                  <Tooltip
                    message={tooltip}
                    width={340}
                    onHover={(duration) =>
                      pushToGA({
                        ...events.mouseover_featureTooltip("buffer", duration),
                      })
                    }
                  />
                </Col>
                {isTemplatePage && (
                  <Col>
                    <TemplatePermissionSelector name="buffer"/>
                  </Col>
                )}
              </Row>
            }
            fields={
              <Row>
                <Col xs display={["flex", "block"]}>
                  <InlineWrapper mr="11">
                    <FormattedMessage {...messages.bufferPreText} />
                  </InlineWrapper>
                  <DesktopBreakpoint>
                    <Field
                      id="buffer"
                      name="buffer"
                      component={InputSelect}
                      width="112px"
                      height="44px"
                      options={options}
                    />
                  </DesktopBreakpoint>
                  <PhoneBreakpoint>
                    <Field
                      id="buffer"
                      name="buffer"
                      component={DefaultSelect}
                      options={options}
                    />
                  </PhoneBreakpoint>
                  <InlineWrapper ml="11">
                    <FormattedMessage {...messages.bufferPostText} />
                  </InlineWrapper>
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>
      <PlanBlocked mt="26px" plan="enterprise">
        <Row mt="26px" mb="26px">
          <Col xs>
            <TemplateBlocked
              blocked={permissions?.bufferCalendarEvents?.value}
              header={
                <Row mb={["26px", "13px"]}>
                  <Col xs display="flex" alignItems="center">
                    <LabelWithMargin>
                      <FormattedMessage {...messages.bufferEventsHeader} />
                    </LabelWithMargin>
                    <Tooltip
                      message={
                        <FormattedMessage
                          {...messages.bufferEventTooltip}
                          values={{
                            br: <br/>,
                            a: (chunks) => (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${helpUrl}buffer-schedule-conf`}
                                onClick={() =>
                                  pushToGA({
                                    ...events.click_link_help("/buffer-schedule-conf"),
                                  })
                                }
                              >
                                {chunks}
                              </a>
                            ),
                          }}
                        />
                      }
                      width={340}
                      onHover={(duration) =>
                        pushToGA({
                          ...events.mouseover_featureTooltip(
                            "bufferCalEvent",
                            duration
                          ),
                        })
                      }
                    />
                    <PlanIcon featurePlan="enterprise" />
                  </Col>
                  {isTemplatePage && (
                    <Col>
                      <TemplatePermissionSelector name="bufferCalendarEvents"/>
                    </Col>
                  )}
                </Row>
              }
              fields={
                <>
                  <Row mb={["26px", "13px"]}>
                    <Col xs={2} sm={1} mt="13px">
                      <Field
                        id="preBufferCalEvent"
                        name="preBufferCalEvent"
                        component={Checkbox}
                        useArrayValue={false}
                        onChange={(e) => handleBufferCalStateChange(e, "pre")}
                      />
                    </Col>
                    <StyledCol xs={10} sm={11} display={["flex", "block"]}>
                      <InlineWrapper mr="11">
                        <FormattedMessage {...messages.preBufferEventsPreText} />
                      </InlineWrapper>
                      <DesktopBreakpoint>
                        <Field
                          id="preBufferCalEventDuration"
                          name="preBufferCalEventDuration"
                          component={InputSelect}
                          width="112px"
                          height="44px"
                          options={bufferCalDurations}
                          isDisabled={preBufferEventEnabled ? undefined : "isDisabled"}
                        />
                      </DesktopBreakpoint>
                      <PhoneBreakpoint>
                        <Field
                          id="preBufferCalEventDuration"
                          name="preBufferCalEventDuration"
                          component={DefaultSelect}
                          options={bufferCalDurations}
                          isDisabled={preBufferEventEnabled ? undefined : "isDisabled"}
                        />
                      </PhoneBreakpoint>
                      <InlineWrapper ml="11">
                        <FormattedMessage {...messages.bufferEventsPostText} />
                      </InlineWrapper>
                    </StyledCol>
                  </Row>
                  <Row>
                    <Col xs={2} sm={1} mt="13px">
                      <Field
                        id="postBufferCalEvent"
                        name="postBufferCalEvent"
                        component={Checkbox}
                        useArrayValue={false}
                        onChange={(e) => handleBufferCalStateChange(e, "post")}
                      />
                    </Col>
                    <StyledCol xs={10} sm={11} display={["flex", "block"]}>
                      <InlineWrapper mr="11">
                        <FormattedMessage {...messages.postBufferEventsPretext} />
                      </InlineWrapper>
                      <DesktopBreakpoint>
                        <Field
                          id="postBufferCalEventDuration"
                          name="postBufferCalEventDuration"
                          component={InputSelect}
                          width="112px"
                          height="44px"
                          options={bufferCalDurations}
                          isDisabled={postBufferEventEnabled ? undefined : "isDisabled"}
                        />
                      </DesktopBreakpoint>
                      <PhoneBreakpoint>
                        <Field
                          id="postBufferCalEventDuration"
                          name="postBufferCalEventDuration"
                          component={DefaultSelect}
                          options={bufferCalDurations}
                          isDisabled={postBufferEventEnabled ? undefined : "isDisabled"}
                        />
                      </PhoneBreakpoint>
                      <InlineWrapper ml="11">
                        <FormattedMessage {...messages.bufferEventsPostText} />
                      </InlineWrapper>
                    </StyledCol>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
      </PlanBlocked>
      <PlanBlocked mt="26px" plan="standard">
        <TemplateBlocked
          blocked={permissions?.availableEventPrefix?.value}
          header={
            <Row mb="10px">
              <Col xs display="flex">
                <LabelWithMargin>
                  <FormattedMessage {...messages.availableEventPrefix} />
                </LabelWithMargin>
                <Tooltip
                  message={
                    <FormattedMessage
                      {...messages.availableEventPrefixTooltip}
                      values={{ br: <br/> }}
                    />
                  }
                  width={340}
                />
              </Col>
              {isTemplatePage && (
                <Col>
                  <TemplatePermissionSelector name="availableEventPrefix"/>
                </Col>
              )}
            </Row>
          }
          fields={
            <>
              <FieldArray
                name="availableEventPrefixes"
                showForm={addingAvailableEventPrefix}
                toggle={() =>
                  setAddingAvailableEventPrefix(!addingAvailableEventPrefix)
                }
                headerText={
                  <FormattedMessage {...messages.addAvailableEventPrefix} />
                }
                closeText={<FormattedMessage {...messages.close} />}
                addButtonText={<FormattedMessage {...messages.add} />}
                placeHolder={props.intl.formatMessage({
                  ...messages.availableEventPrefixPlaceholder,
                })}
                validate={availableEventPrefixesLength}
                component={RenderPrefixes}
              />
              <Row mb="26px" ml={["0px", "-0.5rem" ]} mr={["0px", "-0.5rem" ]}>
                <Button
                  outlined
                  mt="15px"
                  fontSize="14px"
                  fontWeight="normal"
                  height="32px"
                  onClick={(e) => {
                    e.preventDefault();
                    setAddingAvailableEventPrefix(!addingAvailableEventPrefix);
                  }}
                >
                  <FormattedMessage {...messages.addAvailableEventPrefix} />
                </Button>
                <Row></Row>
              </Row>
            </> 
          }
        />
      </PlanBlocked>
      <Row mb="26px">
        <Col xs>
          <TemplateBlocked
            blocked={permissions?.allowAppointmentCancellation?.value}
            header={
              <Row mb="10px">
                <Col xs display="flex" alignItems="center">
                  <Label>
                    <FormattedMessage {...messages.appointmentCancellation} />
                    <Tooltip
                      message={props.intl.formatMessage(
                        { ...messages.appointmentCancellationTooltip },
                        {
                          br: <br />,
                        }
                      )}
                      width={340}
                    />
                  </Label>
                </Col>
                {isTemplatePage && (
                  <Col>
                    <TemplatePermissionSelector name="allowAppointmentCancellation"/>
                  </Col>
                )}
              </Row>
                
            }
            fields={
              <Row mt="12px">
                <Col xs>
                  <Row>
                    <Col xs={4}>
                      <Field
                        name="allowAppointmentCancellation"
                        trueFor={true}
                        component={RadioButton}
                      >
                        <FormattedMessage {...messages.enableAppointmentCancellation} />
                      </Field>
                    </Col>
                    <Col xs={4}>
                      <Field
                        name="allowAppointmentCancellation"
                        trueFor={false}
                        component={RadioButton}
                      >
                        <FormattedMessage {...messages.disableAppointmentCancellation} />
                      </Field>
                    </Col>
                  </Row>
                  {appointmentCancellationState && (
                    <Row alignItems="baseline">
                      <Col xs={3}>
                        <Field
                          name="appointmentCancellationTimeLimit"
                          component={InputSelect}
                          height="39px"
                          width="100px"
                          options={options}
                        />
                      </Col>
                      <InlineWrapper ml="11">
                        <FormattedMessage {...messages.appointmentCancellationTimeLimit} />
                      </InlineWrapper>
                    </Row>
                  )}
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>

      <Hr border="none" height="1px" color="#e5e5e5" mt="20px" />

      <Row mt="20px">
        <Col xs>
          <Button
            disabled={pristine || submitting}
            onClick={props.handleSubmit((values) => onSubmit(values))}
            fontWeight="500"
          >
            <FormattedMessage {...messages.save} />
          </Button>
        </Col>
      </Row>
    </Dashboard>
  );
}

AdvancedSettings.propTypes = {};

export default reduxForm({
  form: "AdvancedSettings",
})(injectIntl(AdvancedSettings));
