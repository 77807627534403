export const formatTextFieldApi = ({
  uid,
  fieldType,
  value,
  restrictionOptions
}, baseField = false) => {
  return {
    booking_calendar_custom_field: {
      uid,
      label_name: value.labelName,
      field_type: fieldType,
      input_required: value.bookingCalendarSettingBookingCalendarCustomField.inputRequired,
      input_hidden: baseField ? value.bookingCalendarSettingBookingCalendarCustomField.inputHidden : null,
      text_field_input_restriction: restrictionOptions[value.bookingCalendarSettingBookingCalendarCustomField.textFieldInputRestriction].type,
      salesforce_field_id: value.salesforceFieldId,
      privacy_policy_url: value.privacyPolicyUrl,
      use_own_privacy_policy: value.useOwnPrivacyPolicy,
    }
  };
};

export const formatDateFieldApi = ({
  uid,
  fieldType,
  value,
}, baseField = false) => {
  return {
    booking_calendar_custom_field: {
      uid,
      label_name: value.labelName,
      field_type: fieldType,
      input_required: value.bookingCalendarSettingBookingCalendarCustomField.inputRequired,
      input_hidden: baseField ? value.bookingCalendarSettingBookingCalendarCustomField.inputHidden : null,
      salesforce_field_id: value.salesforceFieldId,
      privacy_policy_url: value.privacyPolicyUrl,
      use_own_privacy_policy: value.useOwnPrivacyPolicy,
    }
  };
};

export const formatTextAreaApi = ({
  uid,
  fieldType,
  value
}) => {
  return {
    booking_calendar_custom_field: {
      uid,
      label_name: value.labelName,
      field_type: fieldType,
      input_required: value.bookingCalendarSettingBookingCalendarCustomField.inputRequired,
      salesforce_field_id: value.salesforceFieldId,
      privacy_policy_url: value.privacyPolicyUrl,
      use_own_privacy_policy: value.useOwnPrivacyPolicy,
    }
  };
};

export const formatCheckBoxesApi = ({
  uid,
  fieldType,
  value,
}) => {
  return {
    booking_calendar_custom_field: {
      uid,
      label_name: value.labelName,
      field_type: fieldType,
      input_required: value.bookingCalendarSettingBookingCalendarCustomField.inputRequired,
      salesforce_field_id: value.salesforceFieldId,
      privacy_policy_url: value.privacyPolicyUrl,
      use_own_privacy_policy: value.useOwnPrivacyPolicy,
      multiple_choice_options: value.bookingCalendarMultipleChoiceOptions.map((item, index) => ({
        row_order_position: index,
        choice_type_name: item.choiceTypeName,
      }))
    }
  }
};

export const formatInformationCustomFieldsApi = ({
  uid,
  fieldType,
  value
}) => {
  return {
    booking_calendar_custom_field: {
      uid,
      label_name: value.labelName,
      field_type: fieldType,
      input_required: value.bookingCalendarSettingBookingCalendarCustomField.inputRequired,
      custom_field_inputs: value.bookingCalendarCustomFieldInputs,
      salesforce_field_id: value.salesforceFieldId,
      privacy_policy_url: value.privacyPolicyUrl,
      use_own_privacy_policy: value.useOwnPrivacyPolicy,
    }
  };
};
