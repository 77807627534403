import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { defineMessages, FormattedMessage } from 'react-intl';
import PhoneBreakpoint from "@d-lighted/design-system/dist/components/Utils/PhoneBreakpoint";
import DesktopBreakpoint from "@d-lighted/design-system/dist/components/Utils/DesktopBreakpoint";
import IconDots from "../icons/IconDots";
import { MOBILEBREAKPOINT } from "../../constants/breakpoint";
import logo from "../../assets/img/logo.png";

const Wrapper = styled.div`
  display: flex;
`;

const HeaderImage = styled.img`
  width: 50px;
  height: 50px;
  padding-right: 25px;
  padding-left: 40px;
  color: #314143;
  @media (max-width: ${MOBILEBREAKPOINT}){
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const BreadCrumbUl = styled.ul`
  padding: 15px 0px;
  list-style: none;
  @media (max-width: ${MOBILEBREAKPOINT}){
    padding: 0;
    display: flex;
    margin-top: 14px;
  }
`;

const BreadcrumbItem = styled.li`
  display: inline;

  font-size: 13px;
  color: #314143;

  & + & {
    &:before {
      font-family: FontAwesome;
      padding: 18px;
      color: #68878d;
      font-size: 18px;
      content: "\f105";
    }
  }
`;

const BreadCrumbItemLink = styled.div`
  font-size: 13px;
  color: #68878d;
  text-decoration: none;
  display: inline-block;
  padding-right: 5px;
  cursor: pointer;
  @media (max-width: ${MOBILEBREAKPOINT}){
    white-space: nowrap;
  }
`;

const MultiguestBreadCrumbItemLink = styled.div`
  font-size: 13px;
  color: #68878d;
  text-decoration: none;
  display: inline-block;
  padding-right: 5px;
  cursor: pointer;
  width: 250px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;

  @media (max-width: ${MOBILEBREAKPOINT}){
    white-space: nowrap;
  }
`;

const messages = defineMessages({
  dashboard: {
    id: 'components.elements.breadcrumb.dashboard',
    defaultMessage: 'Dashboard',
  }
});

export const BreadCrumb = (props) => {
  const { data } = useSelector((state) => state.calendarSetting);
  const multiguestTitle = useSelector(state => state.multiguest.calendar?.title);
  const params = useParams();

  function generateBreadCrumbItem(item) {
    switch (item) {
      case "multiguest":
        return (
          <MultiguestBreadCrumbItemLink>
            ゲスト複数人で調整: {data?.title}
          </MultiguestBreadCrumbItemLink>
        )
      case "multiguestList":
      return (
        <MultiguestBreadCrumbItemLink>
          調整中一覧: {data?.title}
        </MultiguestBreadCrumbItemLink>
      )
      case "multiguestFinalize":
      return (
        <>
          <BreadcrumbItem>
            <MultiguestBreadCrumbItemLink onClick={() => {
              props.history.push(`/booking_calendars/${params.uid}/multiguest/list`)
            }}>
              ゲスト複数人で調整: {data?.title}
            </MultiguestBreadCrumbItemLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <MultiguestBreadCrumbItemLink>
              {multiguestTitle}
            </MultiguestBreadCrumbItemLink>
          </BreadcrumbItem>
        </>
      )
      default:
        break;
    }
  }

  return (
    <Wrapper>
      <HeaderImage
        className="footer_page_image align-text-bottom"
        src={logo}
        alt="logo"
      />
      {!['/sign_in/saml'].includes(props.location.pathname) && (
        <BreadCrumbUl>
          <BreadcrumbItem>
            <BreadCrumbItemLink onClick={() => {
              const templateListPageOnlyRegex = /^\/templates\/[^/]+$/;
              if(templateListPageOnlyRegex.test(window.location.pathname)) {
                window.location.href = '/'
              } else {
                props.history.push('/');
              }
            }}>
              <PhoneBreakpoint>
                {!(props.label === "dashboard") && (
                  <IconDots/>
                )}
              </PhoneBreakpoint>
              <DesktopBreakpoint>
                <FormattedMessage {...messages.dashboard} />
              </DesktopBreakpoint>
            </BreadCrumbItemLink>
          </BreadcrumbItem>
          {!(props.label === "dashboard" || props.label === "saml_sign_in") && (
            <BreadcrumbItem>
              {generateBreadCrumbItem(props.label)}
            </BreadcrumbItem>
          )}
        </BreadCrumbUl>
      )}
    </Wrapper>
  );
};

BreadCrumb.defaultProps = {};

BreadCrumb.propTypes = {};
