/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { reduxForm, Field, SubmissionError, formValueSelector } from "redux-form";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import createHumps from "lodash-humps/lib/createHumps";
import { merge, snakeCase } from "lodash";

import {
  Button,
  InputSelect,
  Label,
  Checkbox,
  TimePicker,
  Col,
  Hr,
  Row,
  LinkText,
  RadioButton,
  DefaultSelect,
  DesktopBreakpoint,
  PhoneBreakpoint
} from "@d-lighted/design-system";
import { Dashboard } from "components/layouts/Dashboard";
import { isBookingTypeIrregular, isPaidOrTrialPlan } from "utils/userUtils";
import { put, useGet } from "utils/api";
import { actions } from "redux/actions";
import { apiUtils } from "utils/apiUtils";
import { useLocale } from "utils/localeUtils";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import { useValidation } from "utils/validation";
import DefaultCalendar from "components/elements/DefaultCalendar";
import { MOBILEBREAKPOINT } from "constants/breakpoint";
import TemplatePermissionSelector, { convertToOption } from "components/forms/TemplatePermissionSelector";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import messages from "../i18n/Availability";
import SmallCalendar from "../modules/SmallCalendar";

const SeparatorIcon = styled.div`
  border-top: 1px solid #68878d;
  margin-top: -3px;
  margin-left: 13px;
  margin-right: 13px;
  width: 13px;
  opacity: 0.7;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 23px;
    max-width: 13px;
  }
`;

const InlineWrapper = styled.div`
  display: inline-block;
  margin-right: ${(props) => (props.mr ? props.mr : "0")}px;
  margin-left: ${(props) => (props.ml ? props.ml : "0")}px;
  color: #314143;
  font-weight: 200;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    line-height: 1.2;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const CheckboxField = styled.div`
  margin-right: ${(props) => (props.mr ? props.mr : "0")}px;
  margin-left: ${(props) => (props.ml ? props.ml : "0")}px;
  min-width: 65px;
`;

const Link = styled(LinkText)`
  margin-right: ${(props) => (props.mr ? props.mr : "0")}px;
  margin-left: ${(props) => (props.ml ? props.ml : "0")}px;
  text-decoration: underline;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    line-height: 1.2;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const CustomTimePicker = styled(TimePicker)`
  .rc-time-picker-input[disabled] {
    opacity: 1;
    color: rgba(20, 44, 43, 0.2) !important;
    background: rgb(255, 255, 255, 0.65) !important;
    box-shadow: none;
  }
`;

const snakes = createHumps(snakeCase);

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

const BusinessHoursForm = formValueSelector("BusinessHours");

function BusinessHours(props) {
  const { uid, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const { setTemplateUid, saveWithTemplateSettings } = useSavedTemplateSettings();
  useGet(calendarSettingRequest(uid));
  const { data, isSuccess, hasError } = useSelector(
    (state) => state.calendarSetting
  );
  const [locale] = useLocale();
  const { initialize, useNotify, pristine, submitting, intl } = props;
  const dispatch = useDispatch();
  const notify = useNotify(intl);
  const { required } = useValidation(intl);
  const isIrregular = isBookingTypeIrregular();

  const businessTimeState = useSelector(
    (state) => BusinessHoursForm(state, 'businessTimes') || {}
  );

  const simpleBusinessTimes = useSelector(
    (state) => BusinessHoursForm(state, 'simpleBusinessTimes') || {}
  );

  const permissions = useSelector(state => BusinessHoursForm(state, "permissions"));

  const {
    bookingRangeMethod: selectedBookingRangeMethod,
    startDate,
  } = useSelector((state) => {
    const { bookingRangeMethod, startDate, endDate } =
      state.form.BusinessHours?.values || {};
    return {
      bookingRangeMethod,
      startDate: startDate || moment().format("L"),
      endDate: endDate || moment().add(61, "days").format("L"),
    };
  });

  useEffect(() => {
    if (isSuccess) {
      const {
        holidayBlocking,
        businessTimes,
        bookingRangeMethod,
        rangeStartDate,
        rangeEndDate,
        bookingCalendarTemplateUid,
        permissions,
      } = data;
      const minSchedulableTime = {
        value: data.minSchedulableTime,
        label:
          data.minSchedulableTime > 0
            ? `${data.minSchedulableTime}${intl.formatMessage({
                ...messages.bookingRangeDays,
              })}`
            : `${intl.formatMessage({ ...messages.bookingRangeNow })}`,
      };
      const maxSchedulableTime = {
        value: data.maxSchedulableTime,
        label: `${data.maxSchedulableTime}${intl.formatMessage({
          ...messages.bookingRangeDays,
        })}`,
      };
      const minSchedulableBusinessTime = {
        value: data.minSchedulableBusinessTime,
        label: `${data.minSchedulableBusinessTime}${props.intl.formatMessage({
                  ...messages.bookingRangeDays,
                })}`,
      };
      const maxSchedulableBusinessTime = {
        value: data.maxSchedulableBusinessTime,
        label: `${data.maxSchedulableBusinessTime}${props.intl.formatMessage({
                  ...messages.bookingRangeDays,
                })}`,
      };

      const defaultBusinessTimes = {
        1: { enabled: true, openingTime: "09:00", closingTime: "18:00" },
        2: { enabled: true, openingTime: "09:00", closingTime: "18:00" },
        3: { enabled: true, openingTime: "09:00", closingTime: "18:00" },
        4: { enabled: true, openingTime: "09:00", closingTime: "18:00" },
        5: { enabled: true, openingTime: "09:00", closingTime: "18:00" },
        6: { enabled: false, openingTime: "09:00", closingTime: "18:00" },
        7: { enabled: false, openingTime: "09:00", closingTime: "18:00" },
      };
      setTemplateUid(bookingCalendarTemplateUid);

      initialize({
        holidayBlocking,
        minSchedulableTime,
        maxSchedulableTime,
        minSchedulableBusinessTime,
        maxSchedulableBusinessTime,
        bookingRangeMethod: isIrregular ? 'absolute' : bookingRangeMethod,
        startDate: rangeStartDate || moment().format("YYYY-MM-DD"),
        endDate: isIrregular ? moment('2049-12-31').format('YYYY-MM-DD') : (rangeEndDate || moment().add(61, "day").format("YYYY-MM-DD")),
        businessTimes: businessTimes,
        simpleBusinessTimes:
          !isPaidOrTrial && merge(defaultBusinessTimes[1], businessTimes[1]),
        permissions: convertToOption(permissions, intl),
      });
    }
    if (hasError) {
      dispatch({
        type: "calendarSetting_get",
        payload: {
          status: null,
          data: null,
          isSuccess: false,
          hasError: false,
          initial: true,
        },
      });
      props.history.push("/");
    }
  }, [data, locale]);

  if (!isSuccess) {
    return <Redirect to="/" />;
  }

  function getFreeBusinessTimes(values) {
    return {
      1: {
        enabled: true,
        opening_time: values.simpleBusinessTimes.openingTime,
        closing_time: values.simpleBusinessTimes.closingTime,
      },
      2: {
        enabled: true,
        opening_time: values.simpleBusinessTimes.openingTime,
        closing_time: values.simpleBusinessTimes.closingTime,
      },
      3: {
        enabled: true,
        opening_time: values.simpleBusinessTimes.openingTime,
        closing_time: values.simpleBusinessTimes.closingTime,
      },
      4: {
        enabled: true,
        opening_time: values.simpleBusinessTimes.openingTime,
        closing_time: values.simpleBusinessTimes.closingTime,
      },
      5: {
        enabled: true,
        opening_time: values.simpleBusinessTimes.openingTime,
        closing_time: values.simpleBusinessTimes.closingTime,
      },
      6: {
        enabled: !values.holidayBlocking,
        opening_time: "09:00",
        closing_time: "18:00",
      },
      7: {
        enabled: !values.holidayBlocking,
        opening_time: "09:00",
        closing_time: "18:00",
      },
    };
  }

  function getBody(values) {
    const businessTimes = isPaidOrTrial
      ? snakes(values.businessTimes)
      : getFreeBusinessTimes(values);

    const body = {
      business_times: businessTimes,
      min_schedulable_time: values.minSchedulableTime.value,
      max_schedulable_time: values.maxSchedulableTime.value,
      min_schedulable_business_time: values.minSchedulableBusinessTime.value,
      max_schedulable_business_time: values.maxSchedulableBusinessTime.value,
      holiday_blocking: values.holidayBlocking,
      booking_range_method: values.bookingRangeMethod,
    };

    if (values.bookingRangeMethod === "absolute") {
      const { startDate, endDate } = values;

      body.range_start_date = moment(startDate).format("YYYY-MM-DD");
      body.range_end_date = moment(endDate).format("YYYY-MM-DD");
    }

    return body;
  }

  function processResult(result) {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.notifyError, result?.data?.message);
      throw new SubmissionError({ _error: "Error Occur" });
    } else {
      notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
      dispatch({
        type: `${actions.getCalendarSetting.name}_update`,
        payload: { ...apiUtils.formatAxiosResponse(result) },
      });
      return result;
    }
  }

  const validateBusinessHours = (businessTimes) => {
    if (businessTimes == null) return false;

    return Object.getOwnPropertyNames(businessTimes).reduce(
      (acc, key) => acc || businessTimes[key].enabled,
      false
    );
  };

  const onSubmit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

  const handleOnSubmit = async (values) => {
    const body = getBody(values);
    if (!validateBusinessHours(body.business_times)) {
      notify.setError(messages.notifyError, messages.notifyBusinessHours);
      return;
    }
    const url = `/booking_calendar_settings/${uid}`;
    const result = isTemplatePage ? await saveWithTemplateSettings(put(url,body), values.permissions) : await put(url, body);
    
    return processResult(result);
  };

  const minOptions = [
    {
      value: 0,
      label: props.intl.formatMessage({ ...messages.bookingRangeNow }),
    },
  ];
  for (let i = 1; i <= 7; i++) {
    minOptions.push({
      value: i,
      label: `${i}${props.intl.formatMessage({
        ...messages.bookingRangeDays,
      })}`,
    });
  }

  const minBusinessSettingOptions = [];
  for (let i = 0; i <= 7; i++) {
    minBusinessSettingOptions.push({
      value: i,
      label: `${i}${props.intl.formatMessage({
        ...messages.bookingRangeDays,
      })}`,
    });
  }

  const maxOptions = [];
  for (let i = 7; i <= 60; i++) {
    maxOptions.push({
      value: i,
      label: `${i}${props.intl.formatMessage({
        ...messages.bookingRangeDays,
      })}`,
    });
  }

  const options = [];
  [0, 15, 30, 60, 90].forEach((i) => {
    options.push({
      value: i,
      label: `${i}${props.intl.formatMessage({ ...messages.timeMinutes })}`,
    });
  });

  const isPaidOrTrial = isPaidOrTrialPlan();

  const copyToAll = () => {
    props.change("businessTimes", {
      1: { ...businessTimeState[1], enabled: businessTimeState[1].enabled },
      2: { ...businessTimeState[1], enabled: businessTimeState[2].enabled },
      3: { ...businessTimeState[1], enabled: businessTimeState[3].enabled },
      4: { ...businessTimeState[1], enabled: businessTimeState[4].enabled },
      5: { ...businessTimeState[1], enabled: businessTimeState[5].enabled },
      6: { ...businessTimeState[1], enabled: businessTimeState[6].enabled },
      7: { ...businessTimeState[1], enabled: businessTimeState[7].enabled },
    });
  };

  const holidayBlocking = (
    <Field
      id="holidayBlocking"
      name="holidayBlocking"
      component={Checkbox}
      useArrayValue={false}
    >
      <FormattedMessage {...messages.holidayBlockingDescription} />
    </Field>
  );

  const freeHolidayBlocking = (
    <Field
      id="holidayBlocking"
      name="holidayBlocking"
      component={Checkbox}
      useArrayValue={false}
    >
      <FormattedMessage {...messages.freeHolidayBlockingDescription} />
    </Field>
  );

  return (
    <Dashboard>
      {!isIrregular && <>
        <TemplateBlocked
          blocked={permissions?.businessTime?.value}
          header={
            <Row mt={["26px", "40px"]} mb="10px">
              <Col xs>
                <Label>
                  <FormattedMessage {...messages.businessOpeningTime} />
                </Label>
              </Col>
              {isTemplatePage && (
                <Col>
                  <TemplatePermissionSelector name="businessTime" />
                </Col>
              )}
            </Row>
          }
          fields={
            <>
              {!isPaidOrTrial && (
                <Row mb="20px">
                  <Col xs display="flex" alignItems="center">
                    <Field
                      name="simpleBusinessTimes.openingTime"
                      component={CustomTimePicker}
                      maxTime={simpleBusinessTimes.closingTime}
                    />
                    <SeparatorIcon />
                    <Field
                      name="simpleBusinessTimes.closingTime"
                      component={CustomTimePicker}
                      minTime={simpleBusinessTimes.openingTime}
                    />
                  </Col>
                </Row>
              )}
              {isPaidOrTrial && (
                <>
                  <Row mb="14px">
                    <Col xs display="flex" alignItems="center">
                      <CheckboxField mr="16">
                        <Field
                          id="businessTimes.1.enabled"
                          name="businessTimes.1.enabled"
                          component={Checkbox}
                          useArrayValue={false}
                        >
                          <FormattedMessage {...messages.monday} />
                        </Field>
                      </CheckboxField>
                      <Field
                        id="businessTimes.1.openingTime"
                        name="businessTimes.1.openingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[1]?.enabled}
                        maxTime={businessTimeState[1]?.closingTime}
                      />
                      <SeparatorIcon />
                      <Field
                        id="businessTimes.1.closingTime"
                        name="businessTimes.1.closingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[1]?.enabled}
                        minTime={businessTimeState[1]?.openingTime}
                      />
                      <Link ml="11" onClick={copyToAll}>
                        <FormattedMessage {...messages.copyToAll} />
                      </Link>
                    </Col>
                  </Row>
                  <Row mb="14px">
                    <Col xs display="flex" alignItems="center">
                      <CheckboxField mr="16">
                        <Field
                          id="businessTimes.2.enabled"
                          name="businessTimes.2.enabled"
                          component={Checkbox}
                          useArrayValue={false}
                        >
                          <FormattedMessage {...messages.tuesday} />
                        </Field>
                      </CheckboxField>
                      <Field
                        id="businessTimes.2.openingTime"
                        name="businessTimes.2.openingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[2]?.enabled}
                        maxTime={businessTimeState[2]?.closingTime}
                      />
                      <SeparatorIcon />
                      <Field
                        id="businessTimes.2.closingTime"
                        name="businessTimes.2.closingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[2]?.enabled}
                        minTime={businessTimeState[2]?.openingTime}
                      />
                    </Col>
                  </Row>
                  <Row mb="14px">
                    <Col xs display="flex" alignItems="center">
                      <CheckboxField mr="16">
                        <Field
                          id="businessTimes.3.enabled"
                          name="businessTimes.3.enabled"
                          component={Checkbox}
                          useArrayValue={false}
                        >
                          <FormattedMessage {...messages.wednesday} />
                        </Field>
                      </CheckboxField>
                      <Field
                        id="businessTimes.3.openingTime"
                        name="businessTimes.3.openingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[3]?.enabled}
                        maxTime={businessTimeState[3]?.closingTime}
                      />
                      <SeparatorIcon />
                      <Field
                        id="businessTimes.3.closingTime"
                        name="businessTimes.3.closingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[3]?.enabled}
                        minTime={businessTimeState[3]?.openingTime}
                      />
                    </Col>
                  </Row>
                  <Row mb="14px">
                    <Col xs display="flex" alignItems="center">
                      <CheckboxField mr="16">
                        <Field
                          id="businessTimes.4.enabled"
                          name="businessTimes.4.enabled"
                          component={Checkbox}
                          useArrayValue={false}
                        >
                          <FormattedMessage {...messages.thursday} />
                        </Field>
                      </CheckboxField>
                      <Field
                        id="businessTimes.4.openingTime"
                        name="businessTimes.4.openingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[4]?.enabled}
                        maxTime={businessTimeState[4]?.closingTime}
                      />
                      <SeparatorIcon />
                      <Field
                        id="businessTimes.4.closingTime"
                        name="businessTimes.4.closingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[4]?.enabled}
                        minTime={businessTimeState[4]?.openingTime}
                      />
                    </Col>
                  </Row>
                  <Row mb="14px">
                    <Col xs display="flex" alignItems="center">
                      <CheckboxField mr="16">
                        <Field
                          id="businessTimes.5.enabled"
                          name="businessTimes.5.enabled"
                          component={Checkbox}
                          useArrayValue={false}
                        >
                          <FormattedMessage {...messages.friday} />
                        </Field>
                      </CheckboxField>
                      <Field
                        id="businessTimes.5.openingTime"
                        name="businessTimes.5.openingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[5]?.enabled}
                        maxTime={businessTimeState[5]?.closingTime}
                      />
                      <SeparatorIcon />
                      <Field
                        id="businessTimes.5.closingTime"
                        name="businessTimes.5.closingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[5]?.enabled}
                        minTime={businessTimeState[5]?.openingTime}
                      />
                    </Col>
                  </Row>
                  <Row mb="14px">
                    <Col xs display="flex" alignItems="center">
                      <CheckboxField mr="16">
                        <Field
                          id="businessTimes.6.enabled"
                          name="businessTimes.6.enabled"
                          component={Checkbox}
                          useArrayValue={false}
                        >
                          <FormattedMessage {...messages.saturday} />
                        </Field>
                      </CheckboxField>
                      <Field
                        id="businessTimes.6.openingTime"
                        name="businessTimes.6.openingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[6]?.enabled}
                        maxTime={businessTimeState[6]?.closingTime}
                      />
                      <SeparatorIcon />
                      <Field
                        id="businessTimes.6.closingTime"
                        name="businessTimes.6.closingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[6]?.enabled}
                        minTime={businessTimeState[6]?.openingTime}
                      />
                    </Col>
                  </Row>
                  <Row mb="26px">
                    <Col xs display="flex" alignItems="center">
                      <CheckboxField mr="16">
                        <Field
                          id="businessTimes.7.enabled"
                          name="businessTimes.7.enabled"
                          component={Checkbox}
                          useArrayValue={false}
                        >
                          <FormattedMessage {...messages.sunday} />
                        </Field>
                      </CheckboxField>
                      <Field
                        id="businessTimes.7.openingTime"
                        name="businessTimes.7.openingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[7]?.enabled}
                        maxTime={businessTimeState[7]?.closingTime}
                      />
                      <SeparatorIcon />
                      <Field
                        id="businessTimes.7.closingTime"
                        name="businessTimes.7.closingTime"
                        component={CustomTimePicker}
                        disabled={!businessTimeState[7]?.enabled}
                        minTime={businessTimeState[7]?.openingTime}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          }
        />
  
        <TemplateBlocked
          blocked={permissions?.holidayBehavior?.value}
          header={
            <Row mb="10px">
              <Col xs display="flex" alignItems="center">
                <Label>
                  {isPaidOrTrial ? (
                    <FormattedMessage {...messages.bankHolidayBlocking} />
                  ) : (
                    <FormattedMessage {...messages.holidayBlocking} />
                  )}
                </Label>
              </Col>
              {isTemplatePage && (
                <Col>
                  <TemplatePermissionSelector name="holidayBehavior"/>
                </Col>
              )}
            </Row>
          }
          fields={
            <Row mb="26px">
              <Col xs>{isPaidOrTrial ? holidayBlocking : freeHolidayBlocking}</Col>
            </Row>
          }
        />
      </>}
      <PlanBlocked mt="26px" plan="standard">
        
        {!isIrregular ? (
          <TemplateBlocked
            blocked={permissions?.bookingRange?.value}
            header={
              <>
                <Row mb="10px">
                  <Col xs display="flex" alignItems="center">
                    <Label>
                      <FormattedMessage {...messages.bookingRange} />
                    </Label>
                  </Col>
                  {isTemplatePage && (
                    <Col>
                      <TemplatePermissionSelector name="bookingRange" />
                    </Col>
                  )}
                </Row>
              </>
            }
            fields={
              <>
                <Row mb="4px">
                  <Col xs>
                    <Field
                      name="bookingRangeMethod"
                      trueFor="relative"
                      component={RadioButton}
                      display={["block", "initial"]}
                    >
                      <FormattedMessage {...messages.reservation} />
                    </Field>
                  </Col>
                </Row>
                <Row mb="4px">
                  <Col xs>
                    <Field
                      name="bookingRangeMethod"
                      trueFor="ignore_holidays"
                      component={RadioButton}
                      display={["block", "initial"]}
                    >
                      <FormattedMessage {...messages.businessReservable} />
                    </Field>
                  </Col>
                </Row>
                <Row mb="26px">
                  <Col xs>
                    <Field
                      name="bookingRangeMethod"
                      trueFor="absolute"
                      component={RadioButton}
                      display={["block", "initial"]}
                    >
                      <FormattedMessage {...messages.reservable} />
                    </Field>
                  </Col>
                </Row>
              </>
            }
          />)
          : 
          <Row mt={["26px", "40px"]} mb="26px">
            <Col xs>
              <Label>
                <FormattedMessage {...messages.admissionPeriod} />
              </Label>
            </Col>
          </Row>
        }
        <TemplateBlocked
          blocked={permissions?.bookingRange?.value}
          fields={
            <>
              {!isIrregular && selectedBookingRangeMethod === "relative" && (
                <>
                  <Row mb="13px">
                    <Col xs display={["flex", "block"]}>
                      <InlineWrapper mr="11">
                        <FormattedMessage {...messages.bookingRangeMinPreText} />
                      </InlineWrapper>
                      <DesktopBreakpoint>
                        <Field
                          id="minSchedulableTime"
                          name="minSchedulableTime"
                          component={InputSelect}
                          width="114px"
                          height="44px"
                          options={minOptions}
                        />
                      </DesktopBreakpoint>
                      <PhoneBreakpoint>
                        <Field
                          id="minSchedulableTime"
                          name="minSchedulableTime"
                          component={DefaultSelect}
                          options={minOptions}
                        />
                      </PhoneBreakpoint>
                      <InlineWrapper ml="11">
                        <FormattedMessage {...messages.bookingRangeMinPostText} />
                      </InlineWrapper>
                    </Col>
                  </Row>
                  <Row mb="26px">
                    <Col xs display={["flex", "block"]}>
                      <InlineWrapper mr="11">
                        <FormattedMessage {...messages.bookingRangeMaxPreText} />
                      </InlineWrapper>
                      <DesktopBreakpoint>
                        <Field
                          id="maxSchedulableTime"
                          name="maxSchedulableTime"
                          component={InputSelect}
                          width="114px"
                          height="44px"
                          options={maxOptions}
                        />
                      </DesktopBreakpoint>
                      <PhoneBreakpoint>
                        <Field
                          id="maxSchedulableTime"
                          name="maxSchedulableTime"
                          component={DefaultSelect}
                          options={maxOptions}
                        />
                      </PhoneBreakpoint>
                      <InlineWrapper ml="11">
                        <FormattedMessage {...messages.bookingRangeMaxPostText} />
                      </InlineWrapper>
                    </Col>
                  </Row>
                </>
              )}
              {!isIrregular && selectedBookingRangeMethod === "ignore_holidays" && (
                <>
                  <Row mb="13px">
                    <Col xs display={["flex", "block"]}>
                      <InlineWrapper mr="11">
                        <FormattedMessage {...messages.bookingRangeMinPreText} />
                      </InlineWrapper>
                      <DesktopBreakpoint>
                        <Field
                          id="minSchedulableBusinessTime"
                          name="minSchedulableBusinessTime"
                          component={InputSelect}
                          width="114px"
                          height="44px"
                          options={minBusinessSettingOptions}
                        />
                      </DesktopBreakpoint>
                      <PhoneBreakpoint>
                        <Field
                          id="minSchedulableBusinessTime"
                          name="minSchedulableBusinessTime"
                          component={DefaultSelect}
                          options={minBusinessSettingOptions}
                        />
                      </PhoneBreakpoint>
                      <InlineWrapper ml="11">
                        <FormattedMessage {...messages.bookingRangeMinPostText} />
                      </InlineWrapper>
                    </Col>
                  </Row>
                  <Row mb="26px">
                    <Col xs display={["flex", "block"]}>
                      <InlineWrapper mr="11">
                        <FormattedMessage {...messages.bookingRangeMaxPreText} />
                      </InlineWrapper>
                      <DesktopBreakpoint>
                        <Field
                          id="maxSchedulableBusinessTime"
                          name="maxSchedulableBusinessTime"
                          component={InputSelect}
                          width="114px"
                          height="44px"
                          options={maxOptions}
                        />
                      </DesktopBreakpoint>
                      <PhoneBreakpoint>
                        <Field
                          id="maxSchedulableBusinessTime"
                          name="maxSchedulableBusinessTime"
                          component={DefaultSelect}
                          options={maxOptions}
                        />
                      </PhoneBreakpoint>
                      <InlineWrapper ml="11">
                        <FormattedMessage {...messages.bookingRangeMaxPostText} />
                      </InlineWrapper>
                    </Col>
                  </Row>
                </>
              )}
              {selectedBookingRangeMethod === "absolute" && (
                <Row mb="26px">
                  <Col display="flex" width={["100%", "auto"]}>
                    <DesktopBreakpoint>
                      <Field
                        name="startDate"
                        component={SmallCalendar}
                        width="114px"
                        height="44px"
                        minDate={isIrregular ? "" : moment().subtract(1, "days").format("L")}
                        validate={[required]}
                      />
                    </DesktopBreakpoint>
                    <PhoneBreakpoint>
                      <Field
                        name="startDate"
                        component={DefaultCalendar}
                        minDate={isIrregular ? "" : moment().subtract(1, "days").format("L")}
                        isStartDate
                      />
                    </PhoneBreakpoint>
                    <DesktopBreakpoint>
                      <Field
                        name="endDate"
                        component={SmallCalendar}
                        width="114px"
                        height="44px"
                        minDate={
                          isIrregular ? "" : moment(startDate).isBefore(moment())
                            ? moment().subtract(1, "days").format("L")
                            : startDate
                        }
                        validate={[required]}
                      />
                    </DesktopBreakpoint>
                    <PhoneBreakpoint>
                      <Field
                        name="endDate"
                        component={DefaultCalendar}
                        minDate={
                          isIrregular ? "" : moment(startDate).isBefore(moment())
                            ? moment().subtract(1, "days").format("L")
                            : startDate
                        }
                      />
                    </PhoneBreakpoint>
                  </Col>
                </Row>
              )}
            </>
          }
        />
      </PlanBlocked>
      <Hr border="none" height="1px" color="#e5e5e5" mt="20px" />
      <Row mt="20px" mb="20px">
        <Col xs>
          <Button
            disabled={pristine || submitting}
            onClick={props.handleSubmit((values) => onSubmit(values))}
            fontWeight="500"
          >
            <FormattedMessage {...messages.save} />
          </Button>
        </Col>
      </Row>
    </Dashboard>
  );
}

BusinessHours.propTypes = {};

export default reduxForm({
  form: "BusinessHours",
})(injectIntl(BusinessHours));
