import { BOOKING_PAGE_URL_VAR } from "../constants/homeModuleConstants";

export function getSelectedDuration(id, durations = []) {
  const duration = (JSON.parse(localStorage.getItem('selected_duration')) || {})[id];
  if (duration != null && duration !== 'undefined') { // 'undefined' check added for legacy fix
    return "" + duration;
  } else if(durations.includes(60)) {
    return "60";
  } else {
    return "" + durations[0];
  }
}

export function setSelectedDuration(id, duration) {
  if (duration == null) return;
  
  const selectedDuration = JSON.parse(localStorage.getItem('selected_duration')) || {};
  selectedDuration[id] = duration;
  localStorage.setItem('selected_duration', JSON.stringify(selectedDuration));
}

export function generateLinkURLToIframe(alias, uid, isIrregular, duration) {
  return `<iframe width="1280" height="896" src="${generateLinkURL(alias, uid, isIrregular, duration)}"></iframe>`;
}

export function generateLinkURL(alias, uid, isIrregular, duration) {
  const selectedDuration = getSelectedDuration(uid, duration);
  const usableDuration = (duration.includes(Number(selectedDuration))) ? selectedDuration : duration[duration.length - 1];
  return `${process.env.REACT_APP_BOOK_ME_ENDPOINT}/${alias}${isIrregular ? "" : `/${usableDuration}min`}`;
}

export function getSelectedLink(id) {
  return window.localStorage.getItem(`${id}_selected_link`);
}

export function setSelectedLink(id, link) {
  window.localStorage.setItem(`${id}_selected_link`, link || undefined);
}

export function getInstructionMessageState(id) {
  return window.localStorage.getItem(`${id}_instruction_message_state`) === "true";
}

export function setInstructionMessageState(id, state) {
  return window.localStorage.setItem(`${id}_instruction_message_state`, state || false);
}

export function getUrlWithInstructionMessage(instructionMessage, url) {
  if(instructionMessage) {
    if(instructionMessage.includes(BOOKING_PAGE_URL_VAR)) {
      return instructionMessage.replaceAll(BOOKING_PAGE_URL_VAR, url);
    }
    return `${instructionMessage}\n${url}`;
  }
  return url;
}

export function setBookingCalendarSortState(uid, option) {
  const dashboardSort = JSON.parse(localStorage.getItem('dashboard_sort')) || {};
  dashboardSort[uid] = { option };
  localStorage.setItem('dashboard_sort', JSON.stringify(dashboardSort));
};

export function getBookingCalendarSortState(uid) {
  return JSON.parse(localStorage.getItem('dashboard_sort'))?.[uid]?.option;
}

export function setBookingCalendarQuery(uid, query = '', isQueryByEmployee = false, selectedEmployees = []) {
  const dashboardQuery = JSON.parse(localStorage.getItem('dashboard_query')) || {};
  dashboardQuery[uid] = { query, isQueryByEmployee, selectedEmployees };
  localStorage.setItem('dashboard_query', JSON.stringify(dashboardQuery));
};

export function getBookingCalendarQuery(uid) {
  return JSON.parse(localStorage.getItem('dashboard_query'))?.[uid];
}
