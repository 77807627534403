import React from 'react';
import styled from 'styled-components';
import { fontSize } from 'styled-system';
import userDefault from '../../../../assets/img/userDefault.png';
import google_icon from "../../../../assets/img/g_calendar_icon.png";
import outlook_icon from "../../../../assets/img/outlook_icon.png";
import garoon_icon from "../../../../assets/img/garoon_icon.png";
import zoom_icon from "../../../../assets/img/zoom.svg";
import webex_icon from "../../../../assets/img/ico_webex.svg";
import { IconNew } from '@d-lighted/design-system/dist/components/Atoms';


const ItemLeft = styled.div`
  display: block;
  min-width: 0px;
  width: 290px;
  margin-top: 6px;
`;

const ItemImage = styled.img`
  float: left;
  margin-top: 3px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

const ItemText = styled.p`
  padding-left: 12px;
  line-height: 1.5;
  color: ${props => props.selected ?"#fff": props.color };
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  object-fit: contain;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 15px;
  ${fontSize}
`;

const ItemRight = styled.div`
  position: relative;
  margin-right: 20px;
  margin-left: auto;
  display: inline-flex;
  align-items: center;
`;

const Wrapper = styled.div`
  margin: 8px 0px;
  height: 52px;
  display: flex;
  background: ${props => props.selected ?  "#00bbb5": "#fff" };
  border-radius: 6px;
  padding-left: 8px;
`;

const ItemIcons = styled.span`
  margin-right: 5px;
`;

const Logo = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  position: relative;
`;

const Icon = styled(IconNew)`
  font-size: ${props => props?.selected && "20px"};
  color: ${props => props?.selected && "#fff"};
  cursor: ${props => props.selected ?  "default": "pointer" };
`;

const WebexLogo = styled(Logo)`
  width: auto;
`;


const ListItemRecord = (props) => {
  const { item, selected, handleClick , approvalPendingText} = props;
  const isNameMissing = item.name === null && item?.email?.length > 0;
  return <Wrapper selected={selected}>
    <ItemLeft>
      <ItemImage
        src={item?.icon_uri?.url || userDefault}
        />
      <ItemText selected={selected} color={"#314143"}>{!isNameMissing ? item.name : item.email }</ItemText>
      <ItemText selected={selected} color ={"#68878d"} fontSize={12}> {!isNameMissing ? item.email : approvalPendingText } </ItemText>
    </ItemLeft>
    <ItemRight>
      <ItemIcons>
        {item?.authorized_schedulers?.google && <Logo src={google_icon} alt="Google Calendar" />}
        {item?.authorized_schedulers?.outlook && <Logo src={outlook_icon} alt="Outlook" />}
        {item?.authorized_schedulers?.garoon && <Logo src={garoon_icon} alt="Garoon" />}
        {item?.authorized_schedulers?.zoom && <Logo src={zoom_icon} alt="Zoom" />}
        {item?.authorized_schedulers?.webex && <WebexLogo src={webex_icon} alt="Webex" />}
      </ItemIcons>
      <Icon
        isCursor
        className="material-icons"
        color="#00bbb5"
        fontSize="24px"
        ml="4px"
        disabled={selected}
        onClick={() => handleClick(item)}
        selected={selected}
      >
        { !selected ? "add": "done"}
      </Icon>
    </ItemRight>
  </Wrapper>
};

export default ListItemRecord;
