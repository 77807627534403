import React, { useEffect, useState } from "react";
import { reduxForm, Field } from "redux-form";
import { injectIntl } from 'react-intl';
import { default as qS } from "query-string";
import { 
  Row,
  Col,
  Button,
  Loader,
  Label as BaseLabel,
  Hr,
  InputWithLabel,
} from "@d-lighted/design-system";
import styled from "styled-components";
import { SHOW_MOBILE } from "../../../../constants/breakpoint";
import GoogleIcon from '../../../../assets/img/google_icon.svg';
import MicrosoftIcon from '../../../../assets/img/microsoft_icon.svg';
import { useOauth } from "../../hooks/useOauth";
import { getCurrentUser, setReferralSource } from "../../../../utils/userUtils";
import { 
  capitalizedString, leaveOnboarding, 
} from "../../utils/utils";
import { customMedia } from "../../../../utils/mobileBreakpointUtils";
import { Link } from "../../components/Link";
import { useValidation } from "../../../../utils/validation";
import PrivacyAgreement from "./Signup/PrivacyAgreement";
import SignUpGuideDashboard from "../../../../components/layouts/SignUpGuideDashboard";
import EmailInvitation from "./EmailInvitation";

const FieldSection = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${(props) => props.top ? props.top : "40px"} 0 0 32px;
  text-align: left;
  ${customMedia.lessThan('sm')`
    width: 100%;
    margin: 0;
    padding: 0;
  `};
`;

const FormBody = styled.div`
  ${customMedia.lessThan('sm')`
  width: auto;
  text-align: left;
  padding: 16px;
  `};
  .fieldLabel span {
  font-weight: 500;
  }
  .fieldLabel .error-text {
  color: #e84c3d;
  }
  padding: ${props => props.padding ? props.padding : "16px 0"};
`;

const SocialButton = styled(Button)`
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D3D9E5;
  border-radius: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  height: 50px;
  justify-content: center;
  line-height: 22px;
  padding: 0;
  border-radius: 125px;
  width: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const SocialImage = styled.img`
  width: 24px;
  height: 24px;
  padding-right: 20px;
`;

const Label = styled(BaseLabel)`
  letter-spacing: 0px;
  display:block;
`;

const SignupButton = styled.button`
  height: 50px;
  width: 400px;
  max-width: 100%;
  font-size: 16px;
  border: none;
  border-radius: 50px;
  padding: 10px 0px 12px;
  background-color: #28afd5;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const SignUp = (props) => {
  const { intl, useNotify, submitting, handleSubmit } = props;
  const notify = useNotify(intl);
  const params = qS.parse(window.location.search);
  const { actions, isSettingUpdated, hasUpdateError, isLoading } = useOauth(notify);
  const [oauthSignUp, setOAuthSignup] = useState(true);
  const { required, email, inputLength, password, passwordLength, privacyChecked } = useValidation(intl);
  const [invited, setInvited] = useState(false);

  const resetUrl = () => {
    Object.keys(params).forEach(key => delete params[key]);
    props.history.replace({ search: new URLSearchParams(params).toString() });
  }

  const formattedBody = (code, scheduler) => {
    let body = {
      scheduler,
      code,
      redirect_path: `${window.location.origin}/signup`
    }
    return body;
  };

  useEffect(() => {
    const state = params?.state;
    if(["outlook_auth", "google_oauth"].includes(state)) {
      const schedulerName = state.split("_")[0];
      // after OAuth
      if(params?.error) {
        notify.setError("認証エラーが発生しました。", `${capitalizedString(schedulerName)}のトークンがありません。お手数ですが、再度連携後、もう一度お試しください。`);
        return resetUrl();
      }
      if(params?.code) {
        const body = formattedBody(params?.code, schedulerName);
        actions.registerEmployee(body);
        return resetUrl();
      }
    }
    if(params?.utm_source) {
      setReferralSource(params.utm_source);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [window?.location]);

  useEffect(() => {
    if(isSettingUpdated) {
      if(!hasUpdateError) {
        if(getCurrentUser()?.company?.uid) {
          return leaveOnboarding(props);
        }
        return props.history.replace("/onboarding/business_info");
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isSettingUpdated, hasUpdateError]);

  const isMobileView = SHOW_MOBILE(window.innerWidth);

  const startOAuth = (scheduler) => {
    return actions.startOAuth(scheduler, `${window.location.origin}/signup`);
  }

  const formattedAuthBody = (values) => {
    let body = {
      email: values.email,
      password: values.password
    }
    return body;
  };

  const onSubmit = async(values) => {
    window.localStorage.removeItem("auth_headers");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("currentUser");
    const body = formattedAuthBody(values);
    const registered = await actions.registerAuthEmployee(body);
    setInvited(registered);
  };

  if(invited) {
    return(
      <EmailInvitation {...props} />
    )
  }

  return (
    <SignUpGuideDashboard {...props} head="1分でカンタン登録">
      <FieldSection top="0px">
        <FormBody>
          {!oauthSignUp && 
            <>
              <Row mt={isMobileView ? "20px" : "0"}>
                <Col xs>
                  <Field
                    id="email"
                    name="email"
                    component={InputWithLabel}
                    labelLeft={
                      <Label fontSize="14px" color="#000" fontWeight="normal">
                        業務用メールアドレス
                      </Label>
                    }
                    height="44px"
                    placeholder={"example@receptionist.com"}
                    validate={[required, email, inputLength]}
                  />
                </Col>
              </Row>
              <Row mt="15px">
                <Col xs>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    component={InputWithLabel}
                    labelLeft={
                      <>
                        <Label fontSize="14px" color="#000" fontWeight="normal">
                          パスワード (8文字以上)
                        </Label>
                        <BaseLabel fontSize="11px">大文字英字, 小文字英字, 数字, 特殊記号のうち３種類以上含めてください</BaseLabel>
                      </>
                    }
                    height="44px"
                    placeholder={null}
                    validate={[required, inputLength, passwordLength, password]}
                    errorWhiteSpace="break-line"
                  />
                </Col>
              </Row>
            </>
          }
          {oauthSignUp &&
            <>
              <Row mt={isMobileView ? "20px" : "0"}>
                <Col xs>
                  <SocialButton
                    fontWeight="500"
                    width={isMobileView ? "100%" : "311px"}
                    onClick={handleSubmit(() => startOAuth("google"))}
                  >
                    <SocialImage src={GoogleIcon} />
                    Googleアカウントで登録
                  </SocialButton>
                </Col>
              </Row>
              <Row mt="12px">
                <Col xs>
                  <SocialButton
                    fontWeight="500"
                    width={isMobileView ? "100%" : "311px"}
                    onClick={handleSubmit(() => startOAuth("outlook"))}
                  >
                    <SocialImage src={MicrosoftIcon} />
                    Microsoftアカウントで登録
                  </SocialButton>
                </Col>
              </Row> 
            </>
          }
          <PrivacyAgreement
            privacyChecked={privacyChecked}
          />
          {isLoading && !oauthSignUp &&
            <Center>
              <Loader />
            </Center>
          }
          {!oauthSignUp && <Row mt="30px">
            <Col xs>
              <div>
              <SignupButton
                disabled={submitting}
                onClick={handleSubmit((values) => onSubmit(values))}
                fontWeight="500"
                width={["100%", "400px"]}
                height="50px"
                style={{color: "black", background: "#FFD583"}}
              >
                無料体験をはじめる
              </SignupButton>
              </div>
            </Col>
          </Row>}
        </FormBody>
      </FieldSection>
      <FieldSection top="0">
        <FormBody>
          <Row>
            <Col xs>
              <Hr
                border="none"
                height="1px"
                color="#e5e5e5"
                mt="40px"
                ml="0px"
                width="100%"
              />
            </Col>
            <Col style={{"alignSelf": "flex-end"}} xs={3} sm={3} md={2} lg={2}>
              <BaseLabel>または</BaseLabel>
            </Col>
            <Col xs>
              <Hr
                border="none"
                height="1px"
                color="#e5e5e5"
                mt="40px"
                ml="0px"
                width={"100%"}
              />
            </Col>
          </Row>
          <Row mt="14px">
            <Col>
              <Link ml="11" onClick={() => setOAuthSignup(!oauthSignUp)}>
                { oauthSignUp ? 'メールアドレスで新規登録' : 'アカウント連携で新規登録' }
              </Link>
            </Col>
          </Row>
        </FormBody>
      </FieldSection>
    </SignUpGuideDashboard>
  );
}

SignUp.propTypes = {};

export default injectIntl(
  reduxForm({
    form: "sign_up",
  })(SignUp)
);
