import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";

import DateTimeCard from "./DateTimeCard";
import {
  defaultRowMargin,
  StyledLink,
  Div,
} from "../Helper";
import CheckBoxCase from "./modules/CheckBox";
import RadioButtonCase from "./modules/RadioButton";
import TextAreaCase from "./modules/TextArea";
import TextFieldCase from "./modules/TextField";
import RequiredMark from "./modules/RequiredMark";
import DropdownCase from "./modules/Dropdown";
import DateCase from "./modules/DateField";
import BlockOfTextCase from "./modules/BlockOfText";

import {
  Button,
  Checkbox,
  Col,
  Heading,
  InputWithLabel as InputField,
  Row,
} from "@d-lighted/design-system";
import { getCurrentUser, isBookingTypeIrregular } from "../../../utils/userUtils";

const MainRow = styled(Row)`
  width: 54.9vw;
  @media(min-width: 1920px) {
    width: 39vw;
  }
`;

const Form = styled.form`
  width: 380px;
`;

const OptionButton = styled.div`
  cursor: pointer;
  width: fit-content;
  background: none;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #00bbb5;
  margin: 10px 0;

  ${({ disabled }) => {
  if (disabled) {
    return `
        pointer-events: none;
        opacity: 0.3;
        user-select: none;
      `;
  }
  return '';
}}
`;

const AddParticipantButton = styled(OptionButton)`
  &:after {
    content: '';
    width: 10.3px;
    height: 21px;
    margin: 0 0 0 8px;
    font-family: FontAwesome;
    position: relative;
    top: -3px;
  }
`;

const BatchAddButton = styled(OptionButton)`
  margin: 10px 0;
  &:after {
    content: '';
    width: 10.3px;
    height: 21px;
    margin: 0 0 0 23px;
    font-family: FontAwesome;
  }
`;


function Confirmation(props) {
  const isIrregular = isBookingTypeIrregular();
  const data = props?.data;
  const today = moment();
  const { year, month, date, day, time, nextHour } = props.selectedHours || {
    year: today.format("YYYY"),
    month: today.format("MM"),
    date: today.format("DD"),
    day: today.get("day"),
    time: `${today.format("HH")}:00`,
    nextHour: `${today.format("HH")}:30`,
  };

  const formData = useSelector(state => state.form.InputScreenSettings?.values?.fields) || [];
  const reservationFormData = useSelector(state => state.form.ReservationFormSettings?.values) || props?.data;
  const inputScreenSettings = useSelector(state => state.form.InputScreenSettings?.values) || {};
  const multipleguestAllowed = inputScreenSettings.multipleguestAllowed || false;
  const companyIsRequired = inputScreenSettings.companyNameFieldIsRequired || false;
  const guestNameIsRequired = inputScreenSettings.guestNameFieldIsRequired || false;
  const companyIsHidden = inputScreenSettings.companyNameFieldIsHidden || false;
  const guestNameIsHidden = inputScreenSettings.guestNameFieldIsHidden || false;
  const allowSplitGuestName = data.allowSplitGuestName || false;
  const showCustomPrivacyPolicy = (reservationFormData?.useOwnPrivacyPolicy && reservationFormData?.privacyPolicyUrl) || false;
  const privacyPolicyUrl = reservationFormData?.privacyPolicyUrl;
  const defaultPolicyUrl = "https://help.receptionist.jp/?p=402";
  const companyName = getCurrentUser()?.company?.name;

  return (
    <React.Fragment>
      <MainRow mt="30px" justifyContent="center">
        <Col xs justifyContent="center" display="inline-flex" width="100%">
          <Form>
            <Row mb="1rem" ml={defaultRowMargin} justifyContent="center">
              <Col pb="25px">
                <Div textAlign="center">
                  <Heading size={2}>{isIrregular ? "スタッフ登録" : "アポイントメント内容確認"}</Heading>
                </Div>
              </Col>
            </Row>
            {!isIrregular &&
              <Row display="flex" alignItems="center" mb="1rem">
                <DateTimeCard
                  year={year}
                  month={month}
                  date={date}
                  day={day}
                  time={time}
                  nextHour={nextHour}
                  label="日時"
                />
              </Row>
            }
            {allowSplitGuestName && !guestNameIsHidden && (
              <>
                {!guestNameIsHidden && (
                  <Row mt="15px" alignItems="center" mb="1rem" display="flex">
                    <InputField
                      name="lastName"
                      labelLeft={
                        <span>
                          氏名(姓)
                          {guestNameIsRequired && <RequiredMark />}
                        </span>
                      }
                      placeholder=""
                    />
                  </Row>
                )}
                <Row alignItems="center" mb="15px">
                  <InputField
                    name="firstName"
                    labelLeft={
                      <span>
                        氏名(名)
                        {guestNameIsRequired && <RequiredMark />}
                      </span>
                    }
                    placeholder=""
                  />
                </Row>
              </>
            )}
            {!guestNameIsHidden && !allowSplitGuestName && <Row mt="15px" alignItems="center" mb="1rem">
              <InputField
                id="fullName"
                labelLeft={
                  <span>
                    氏名
                    {guestNameIsRequired && <RequiredMark />}
                  </span>
                }
                placeholder=""
              />
            </Row>}
            {!companyIsHidden && (
              <Row mt="15px" alignItems="center" mb="1rem">
                <InputField
                  id="companyName"
                  labelLeft={
                    <span>
                      会社名
                      {companyIsRequired && (
                        <>
                          <RequiredMark />
                          <span style={{ fontSize: '12px' }}>
                            （個人の方は&ldquo;個人&rdquo;とご入力ください）
                          </span>
                        </>
                      )}
                    </span>
                  }
                  placeholder=""
                />
              </Row>
            )}
            <Row mt="15px" alignItems="center" mb="1rem">
              <InputField
                id="email"
                labelLeft={
                  <span>
                    メールアドレス
                    <RequiredMark />
                  </span>
                }
                placeholder=""
              />
            </Row>
            {multipleguestAllowed && (
              <>
                <Row>
                  <Col>
                    <AddParticipantButton
                      onClick={() => {}}
                    >
                      同席される方を追加
                    </AddParticipantButton>
                  </Col>
                </Row>
                <Row mb={20}>
                  <Col>
                    <BatchAddButton
                      onClick={() => {}}
                    >
                      同席者情報の一括登録
                    </BatchAddButton>
                  </Col>
                </Row>
              </>
            )}
            {formData.map((data, index) => {
              switch (data.fieldType) {
                case "text_field": {
                  return (
                    <TextFieldCase key={`${data.uid}-${index}`} id={`${data.uid}-${index}`} data={data} />
                  )
                }
                case "text_area": {
                  return (
                    <TextAreaCase key={`${data.uid}-${index}`} id={`${data.uid}-${index}`} data={data} />
                  )
                }
                case "radio_button": {
                  return (
                    <RadioButtonCase key={`${data.uid}-${index}`} id={`${data.uid}-${index}`} data={data} />
                  )
                }
                case "check_box": {
                  return (
                    <CheckBoxCase key={`${data.uid}-${index}`} id={`${data.uid}-${index}`} data={data} />
                  )
                }
                case "block_of_text": {
                  return (
                    <BlockOfTextCase key={`${data.uid}-${index}`} id={`${data.uid}-${index}`} data={data} />
                  )
                }
                case "dropdown": {
                  return (
                    <DropdownCase key={`${data.uid}-${index}`} id={`${data.uid}-${index}`} data={data} />
                  )
                }
                case "date": {
                  return (
                    <DateCase key={`${data.uid}-${index}`} id={`${data.uid}-${index}`} data={data} />
                  )
                }
                default: {
                  return null;
                }
              }
            })}
            <Div mt="30px" display="flex" justifyContent="center" mb="1rem">
              <Checkbox useArrayValue={false}>
                {showCustomPrivacyPolicy ?
                  <>
                    調整アポ運営会社 株式会社RECEPTIONISTの
                    <br />
                    <StyledLink
                      tabIndex="-1"
                      href={defaultPolicyUrl}
                      target="_blank"
                      color="#00bbb5"
                    >
                      個人情報取り扱い
                    </StyledLink>
                    {`と${companyName}の`}
                    <br />
                    <StyledLink
                      tabIndex="-1"
                      href={privacyPolicyUrl}
                      target="_blank"
                      color="#00bbb5"
                    >
                      個人情報取り扱い
                    </StyledLink>
                    に同意します。
                  </>
                :
                  <>
                    <StyledLink
                      tabIndex="-1"
                      href={defaultPolicyUrl}
                      target="blank"
                      color="#00bbb5"
                    >
                      個人情報取り扱い
                    </StyledLink>
                    に同意します。
                  </>
                }
              </Checkbox>
            </Div>
            <Row
              mt="19px"
              justifyContent="space-between"
              display="flex"
            >
              {!isIrregular && <Div>
                <Button
                  outlined
                  width="130px"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("Go Back")
                  }}
                >
                  日時を選び直す
                </Button>
              </Div>}
              <Button
                className="submit_button"
                width={isIrregular ? "100%" : "250px"}
                onClick={(e) => {
                  e.preventDefault();
                  console.log("Submitted");
                }}
              >
                アポイントメントを確定
              </Button>
            </Row>
          </Form>
        </Col>
      </MainRow>
    </React.Fragment>
  );
}

export default Confirmation;
