import React from "react";
import { useSelector } from "react-redux";
import { Hr } from "@d-lighted/design-system";
import { useLocation } from "react-router-dom";
import { getCurrentUser, isEnterprisePlan, isShowingBusinessCard, isStandardPlan } from "../../utils/userUtils";
import { moment, getJapaneseDay } from "./momentUtils";
import * as constants from "./constants/previewConstants"
import { emailLogoPath } from "./Helper";
import QRCodeImage from "../../assets/img/qr_code.png";
import {
  Wrapper,
  LeadText,
  EnglishText,
  Logo,
  Image,
  MainContent,
  EmailContent,
  EmailContentFirstColumn,
  EmailContentSecondColumn,
  ClosingText,
  SubClosingText,
  EmailFooter,
  FooterText,
  ContactButton,
  CancelAppointmentButton,
  HostSectionHeader,
  HostSectionText,
  MainContentWithBorder,
  BusinessCardContentWrapper,
  BusinessCardImageWrapper,
  BusinessCardImage,
  BusinessCardButtonWrapper,
  BusinessCardButtonLink,
  BusinessCardBodyTextWrapper,
} from "./components/Mailer";

export default function AppointmentVisitorEmail() {
  const calendarSettings = useSelector((state) => state.calendarSetting);
  const calendarEmailCustomizations = useSelector((state) => state.calendarEmailCustomizations);
  const {
    title,
    bookingCalendarCustomFields,
    pageType,
    appointmentCancellationTimeLimit,
    allowAppointmentCancellation
  } = calendarSettings?.data || {};
  const currentUser = getCurrentUser();
  const touchless = currentUser.company.touchless;
  const today = moment();
  const appointmentDate =  `${today.format("MM/DD")} (${getJapaneseDay(today.day())}) 09:00 - 10:00`;
  const location = useLocation();
  const { name, email } = currentUser;
  const commonEditPage = location.pathname.split("/").slice(-1)[0] === 'email_notifications';
  const reminderEmailEditPage = location.pathname.split("/").slice(-1)[0] === 'reminder_email';
  const isEnterpriseCustomization = isEnterprisePlan() && calendarSettings?.data?.pageType;
  const completedMessageEmail = useSelector(state => {
     if(isStandardPlan()){
       if(commonEditPage) {
        return state?.form?.EmailNotifications?.values?.completedMessageEmail;
       }
       return calendarEmailCustomizations?.commonEmailCustomization?.completedMessageEmail;
     }
     return null;
  });
  const { personInCharge, logo, contactInformation } = useSelector(state => {
    if(isEnterpriseCustomization){
      if(commonEditPage){
        return state?.form?.EmailNotifications?.values || {};
      }
      return calendarEmailCustomizations?.commonEmailCustomization || {};
    }
    return {};
  });
  const { bookingCompleteleadText, reminderLeadText } =  useSelector(state => {
    if(isEnterpriseCustomization){
      if(commonEditPage){
        return {
          "bookingCompleteleadText": calendarEmailCustomizations?.bookingCompleteEmailCustomization?.leadText || constants.defaultCompleteEmailLeadText,
          "reminderLeadText": calendarEmailCustomizations?.reminderEmailCustomization?.leadText || constants.reminderEmailLeadText
        }
      }
      return {
        "bookingCompleteleadText": state?.form?.CompleteEmailNotification?.values?.leadText || constants.defaultCompleteEmailLeadText,
        "reminderLeadText": state?.form?.ReminderEmailNotification?.values?.leadText || constants.reminderEmailLeadText
      }
    }
    return { "bookingCompleteleadText": constants.defaultCompleteEmailLeadText, "reminderLeadText": constants.reminderEmailLeadText };
  });

  return (
    <Wrapper>
      <Logo src={emailLogoPath(logo?.url || logo)}/>
      <LeadText >
        {"{お客様の会社名}"}<br/>
        {"{お客様の氏名}"}様<br/>
        <br/>
        {reminderEmailEditPage ? (
          <EnglishText>
            {reminderLeadText}
          </EnglishText>
        ):
          bookingCompleteleadText && <EnglishText>
            {bookingCompleteleadText}
          </EnglishText>
        }
      </LeadText>
      <h2>概要 / Overview</h2>
      <MainContent>
        <EmailContent>
          <EmailContentFirstColumn>
            予約ページ名<br/>
            <small>Booking page</small>
          </EmailContentFirstColumn>
          <EmailContentSecondColumn>
            {title}
          </EmailContentSecondColumn>
        </EmailContent>
        <EmailContent>
          <EmailContentFirstColumn>
            ご来訪日時<br/>
            <small>Datetime</small>
          </EmailContentFirstColumn>
          <EmailContentSecondColumn>
            {appointmentDate}<br/>
            <br/> UTC+0900 Asia/Tokyo
          </EmailContentSecondColumn>
        </EmailContent>
        <EmailContent>
          <EmailContentFirstColumn>
            受付コード<br/>
            <small>Reception code</small>
          </EmailContentFirstColumn>
          <EmailContentSecondColumn>
            12345
          </EmailContentSecondColumn>
        </EmailContent>
        {touchless && <EmailContent>
          <EmailContentFirstColumn>
            タッチレス受付用QRコード<br/>
            <small>QR code <br/> for touchless reception</small>
          </EmailContentFirstColumn>
          <EmailContentSecondColumn>
          <Image src={QRCodeImage}/>
          </EmailContentSecondColumn>
        </EmailContent>}
        <EmailContent>
          <EmailContentFirstColumn>
            担当者<br/>
            <small>Contact person</small>
          </EmailContentFirstColumn>
          <EmailContentSecondColumn>
            {personInCharge || constants.defaultPersonInCharge}
          </EmailContentSecondColumn>
        </EmailContent>
        <EmailContent>
          <EmailContentFirstColumn>
            お客様<br/>
            <small>Guest</small>
          </EmailContentFirstColumn>
          <EmailContentSecondColumn>
            {constants.defaultPersonInDest}
          </EmailContentSecondColumn>
        </EmailContent>
        {bookingCalendarCustomFields.length > 0 && <EmailContent>
          <EmailContentFirstColumn>
            お客様の情報<br/>
            <small>Person-in-Destination-Information</small>
          </EmailContentFirstColumn>
          <EmailContentSecondColumn>
            {bookingCalendarCustomFields.map(customField => (
              customField.labelName
            )).join("\n ")}
          </EmailContentSecondColumn>
        </EmailContent>}
        <EmailContent>
          <EmailContentFirstColumn>
            会議名<br/>
            <small>Meeting title</small>
          </EmailContentFirstColumn>
          <EmailContentSecondColumn>
            来訪：会社：氏名１ 様
          </EmailContentSecondColumn>
        </EmailContent>
        {completedMessageEmail && <EmailContent>
          <EmailContentFirstColumn>
            お客様へのご案内<br/>
            <small>Completion message</small>
          </EmailContentFirstColumn>
          <EmailContentSecondColumn>
            {completedMessageEmail}
          </EmailContentSecondColumn>
        </EmailContent>}
      </MainContent>
      { pageType && (
        <>
          <HostSectionHeader>日程の変更を希望される場合</HostSectionHeader>
          <HostSectionText>お手数ですが、以下までご連絡ください。</HostSectionText>
          <MainContent>
            <EmailContent>
              <EmailContentFirstColumn>
                ご連絡先<br/>
                <small>Contact email</small>
              </EmailContentFirstColumn>
              <EmailContentSecondColumn>
                {
                  contactInformation ?
                    <span>{contactInformation}</span>
                    :
                    <>
                      {name}<br/>
                      <a href={`mailto:${email}`}>{email}</a>
                    </>
                }
              </EmailContentSecondColumn>
            </EmailContent>
          </MainContent>
        </>
      )}
      { allowAppointmentCancellation &&
        <>
          <HostSectionHeader>日程の変更 • キャンセルを希望される場合</HostSectionHeader>
          <h2>ご予約時間の({appointmentCancellationTimeLimit}分)前までにお手隙をお願いします</h2>
          <CancelAppointmentButton>このアポイントメントをキャンセル</CancelAppointmentButton>
        </>
      }
      {isShowingBusinessCard() && (
        <>
          <HostSectionHeader>担当者オンライン名刺</HostSectionHeader>
          <MainContentWithBorder>
            <BusinessCardContentWrapper>
              <BusinessCardImageWrapper>
                <BusinessCardImage src="https://receptionist-assets.s3.ap-northeast-1.amazonaws.com/others/sansan_meishi_maker_logo.png" alt="Sansan名刺メーカー" />
                <BusinessCardImage src="https://receptionist-assets.s3.ap-northeast-1.amazonaws.com/others/sansan.png" alt="Sansan" />
                <BusinessCardImage src="https://receptionist-assets.s3.ap-northeast-1.amazonaws.com/others/eight.png" alt="8 card" />
              </BusinessCardImageWrapper>
              <BusinessCardButtonWrapper>
                <BusinessCardButtonLink>
                  名刺を受け取る
                </BusinessCardButtonLink>
              </BusinessCardButtonWrapper>
              <BusinessCardBodyTextWrapper>
                <span style={{margin: '0 auto'}}>
                  上記ボタンより、Sansan社提供のオンライン名刺交換画面に遷移し、
                  <br />
                  オンライン名刺交換が可能となります。
                </span>
              </BusinessCardBodyTextWrapper>
            </BusinessCardContentWrapper>
          </MainContentWithBorder>
        </>
      )}
      {reminderEmailEditPage? (
        <>
          <SubClosingText>
            それでは当日、有意義な時間をお過ごしください。<br />
            We hope you have a nice day.
          </SubClosingText>
          <ClosingText>
            {constants.AppointmentreminderEmailClosingText}
          </ClosingText>
        </>
      ):
        <>
          <h1>アポ当日の受付方法 / How to register on the day of the appointment</h1>
          <ClosingText>
            弊社受付において上記の受付コードで受付をしてください。<br/>
            その後、上記のQRコードをスマホに表示するか、添付のPDFを印刷して持参いただき、<br/>
            ゲートにかざして入場してください。<br/>
            ご不明な点がございましたら、面会担当者までお問い合わせください。<br/>
            Please register at our reception desk with the above reception code. <br/>
            After that, please display the above QR code on your smartphone or print out the attached <br/> PDF file and bring it with you. <br/>
            Please hold it over the gate to enter. <br/>
            If you have any questions, please contact the visiting staff. <br/>
          </ClosingText>
          <ClosingText>
            ※このメールにお心当たりのない場合は破棄してください。<br/>
            ※If you do not requested this email, please ignore it.
          </ClosingText>
        </>
      }
      <EmailFooter>
        <Logo src={emailLogoPath(logo?.url || logo)}/>
        <FooterText>
          Powered by RECEPTIONIST
        </FooterText>
        <Hr border="none" height="1px" color="#e5e5e5" m="15px" />
        <FooterText>
          ご不明な点がございましたら、下記の調整アポ事務局までお問い合わせください。
        </FooterText>
        <ContactButton>お問い合わせ</ContactButton>
      </EmailFooter>
    </Wrapper>
  )
}
