import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Booking from "./bigCalendar/Booking";
import Confirmation from "./confirmationForm/Confirmation";
import Complete from "./confirmationPage/Complete";
import { Row } from "@d-lighted/design-system";
import { calendarIntegratedUser } from "../forms/MemberSelection/utils";
import CalendarNotIntegrated from "./CalendarNotIntegrated";
import AppointmentVisitVisiteeEmail from "./AppointmentVisitVisiteeEmail";
import AppointmentVisitorEmail from "./AppointmentVisitorEmail";
import { getSelectedDuration } from "../../views/home/utils/utils";
import NavigationLogo from "../../assets/img/Icons-Navigation-Logo.svg";
import { isBookingTypeIrregular } from "../../utils/userUtils";

const Wrapper = styled.div`
  position: relative;
  border: solid 1px #d2e9e7;
  border-radius: 5px;
  ${props => !props.showConfirmation && `
    padding-right: 30px;
  `}
  overflow-y: scroll;
  overflow-x: hidden;
  width: 60vw;
  height: 45vw;
`;

const WrapperWithoutBorder = styled.div`
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 60vw;
  height: 45vw;
`;

const Nav = styled.div`
  min-height: 104px;
  background: white;
  align-items: center !important;
  display: flex !important;
  margin-right: -30px;
  border-radius: 5px 5px 0 0;
`;

const HeaderDiv = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 40px;
  width: 100%;
`;

const Header = styled.p`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.48;
  letter-spacing: 1.05px;
  color: rgb(49, 65, 67);
`;

const SubHeader = styled.p`
  font-size: 12px;
  font-weight: 200;
  line-height: 1.46;
  color: rgb(104, 135, 141);
  margin-left: 15px;
  white-space: pre-wrap;
`;

const BookingDiv = styled.div`
  ${(props) =>
    props.showConfirmation ?
    `
    display: flex;
    justify-content: center;
    ` :
    `
    padding-left: 40px;
  `}
`;

const Footer = styled.div`
  text-align: center;
  font-size: 10px;
  color: rgb(49, 65, 67);
  opacity: 0.7;
  margin-top: 15px;
  margin-bottom: 20px;
`;

const FooterImage = styled.img`
  margin-right: 11px;
  height: 15px;
  width: 15px;
  opacity: 0.6;
`;

const OnlineMessageWrapper = styled(Row)`
  padding-top: 71px;
  margin-bottom: 95px;
  text-align: center;

  & p {
    width: 100%;
    font-size: 15px;
    color: #314143;
    letter-spacing: 0.1px;
    line-height: 1.6;
  }
`;

export function headerFormatDuration(toParseDuration) {
  const duration = parseInt(toParseDuration, 10);

  const hour = Math.floor(duration / 60);
  const minutes = duration % 60;

  const hourString = hour > 0 ? `${hour}時間` : '';
  const minuteString = minutes > 0 ? `${minutes}分` : '';

  return `${hourString}${minuteString}`;
}

export default function Preview(props) {
  const isIrregular = isBookingTypeIrregular();
  const { data } = useSelector((state) => state.calendarSetting);
  const { status } = useSelector((state) => state.online);

  const { settings } = useParams();
  const isTemplatePage = [settings].includes('templates');

  const online = isTemplatePage ? true : status[data.alias]?.online;

  const title = useSelector((state) => state.form?.General?.values?.title) || data.title;
  const completedMessageWebpage = useSelector((state) => state.form?.ReservationFinished?.values?.completedMessageWebpage) || data.completedMessageWebpage;
  const customRedirect = useSelector((state) => state.form?.ReservationFinished?.values?.customRedirect);
  const description = useSelector(
    (state) => state.form?.General?.values?.description
  ) || data.description;
  const attendanceRule = useSelector(
    (state) => state.form?.Meetings?.values?.attendanceRule
  ) || data.attendanceRule;

  const selectedDuration = getSelectedDuration(data.uid, data.durations);
  const durations = useSelector(
    (state) => state.form?.Meetings?.values?.durations
  ) || selectedDuration != null ? [selectedDuration] : data.durations;

  const meetingsForm = useSelector((state) => state.form?.Meetings);
  const members = useSelector((state) => state.form?.Meetings?.values?.members) || data.members;
  const optionalAttendeesCount = useSelector((state) => state.form?.Meetings?.values?.optionalAttendeesNumber?.value) || 0;
  const attendeesLimit = data.bookingCalendarAttendeesLimit;

  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const currentLocation = locationArray[locationArray.length - 1];
  const meetingType = meetingsForm?.values?.meetingType || data.meetingType;
  const webConferenceType = meetingsForm?.values?.webConferenceType || data.webConferenceType;
  const reminderAllowed = useSelector((state) => {
    if(currentLocation === "reminder_email") {
      return state?.form?.ReminderEmailNotification?.values?.reminderAllowed;
    }
    return true;
  });

  const showConfirmationForm = isIrregular ? true : [
    "booking_form",
    "input_screen_settings",
    "booking_form_settings",
  ].includes(currentLocation);

  const showConfirmationPage = [
    "confirmation_message",
  ].includes(currentLocation);

  const showEmailPreview = [
    "booking_complete",
    "reminder_email",
    "email_notifications"
  ].includes(currentLocation);

  const showPreviewBody = reminderAllowed;

  const isCalendarIntegrated = () => {
    if(isTemplatePage) return true;

    const registeredAttendees = members.filter(member => member.name !== null && member.attendee);
    const customMustAttendees = members.filter(member => member.name !== null && member.mustAttend);
    const customOptionalAttendees = members.filter(member => member.name !== null && !member.mustAttend && member.attendee);
    if(attendeesLimit) {
      const allAttendees = members.filter(member => member.attendee);
      const allCustomAttendees = members.filter(member => member.attendee || member.mustAttend);
      if(attendanceRule === "custom_will_attend" && allCustomAttendees.length > attendeesLimit)
        return false;
      if(attendanceRule !== "custom_will_attend" && allAttendees.length > attendeesLimit)
        return false;
    }
    if(attendanceRule === "all_will_attend") {
      if(!registeredAttendees.every(member => calendarIntegratedUser(member.authorizedSchedulers))) return false;
      if(["zoom", "webex"].includes(webConferenceType)) {
        return registeredAttendees.some(
          member => webConferenceType === "zoom" ? member.authorizedSchedulers?.zoom : member.authorizedSchedulers?.webex
        );
      }
      return true;
    }
    if(attendanceRule === "custom_will_attend") {
      if(!(customMustAttendees.every(member => calendarIntegratedUser(member.authorizedSchedulers)))) return false;
      if(["zoom", "webex"].includes(webConferenceType) && customMustAttendees.length > 0) {
        if(!customMustAttendees.some(
          member => webConferenceType === "zoom" ? member.authorizedSchedulers?.zoom : member.authorizedSchedulers?.webex
        ))
          return false;
      }
      if(customMustAttendees.length === 0 && ["zoom", "webex"].includes(webConferenceType)) {
        if(!customOptionalAttendees.every(
          member => webConferenceType === "zoom" ? member.authorizedSchedulers?.zoom : member.authorizedSchedulers?.webex
        ))
          return false;
      }
      let integratedOptionalCount = 0;
      customOptionalAttendees.forEach(member => {
        if(calendarIntegratedUser(member.authorizedSchedulers)) integratedOptionalCount += 1;
      });
      return integratedOptionalCount >= optionalAttendeesCount;
    }
    // one_will_attend / random_will_attend
    if(["zoom", "webex"].includes(webConferenceType) && meetingType === "web_conference") {
      return registeredAttendees.every(
        member => webConferenceType === "zoom" ? member.authorizedSchedulers?.zoom : member.authorizedSchedulers?.webex
      );
    }
    return registeredAttendees.some(member => calendarIntegratedUser(member.authorizedSchedulers));
  }

  return (
    <>
      {showPreviewBody ?
        <Wrapper showConfirmation={showConfirmationForm || showConfirmationPage || showEmailPreview}>
          {!showEmailPreview && <>
            <Nav>
              {online && <HeaderDiv>
                <Header>{title} - {headerFormatDuration(durations[0])}</Header>
                <SubHeader>{description}</SubHeader>
              </HeaderDiv>}
            </Nav>
            <BookingDiv showConfirmation={showConfirmationForm || showConfirmationPage}>
              {online && isCalendarIntegrated() &&
                (showConfirmationForm ? <Confirmation data={data} /> : (
                  showConfirmationPage ? <Complete completedMessageWebpage={completedMessageWebpage} customRedirect={customRedirect} /> :
                    <Booking isPreview selectedDuration={selectedDuration} {...props} />
                ))
              }
              {!online && isCalendarIntegrated() && (
                <OnlineMessageWrapper>
                  <p>
                    提供されたリンクは許可されていません。正しいリンクでお試しください。
                  </p>
                </OnlineMessageWrapper>
              )}
              {!isCalendarIntegrated() && (
                <CalendarNotIntegrated />
              )}
            </BookingDiv>
          </>}
          { showEmailPreview && (meetingType === "coming_to_visit" ? <AppointmentVisitorEmail/> : <AppointmentVisitVisiteeEmail/>)}
          {!showEmailPreview && <Footer>
            <FooterImage
              src={NavigationLogo}
            />
            Powered by RECEPTIONIST Inc.
          </Footer>}
        </Wrapper>
      :
        <WrapperWithoutBorder/>
      }
    </>
  );
}

Preview.defaultProps = {
  title: null,
  description: null,
  showConfirmation: false,
};
