/* eslint-disable  react-hooks/exhaustive-deps*/
import React, {useEffect} from "react";
import { reduxForm, Field, SubmissionError, formValueSelector } from "redux-form";
import { FormattedMessage, injectIntl} from 'react-intl';
import { createAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import {
  Button,
  Input,
  RadioButton,
  TextAreaWithLabel,
  Col,
  Row,
  Hr,
  DesktopBreakpoint,
  PhoneBreakpoint
} from "@d-lighted/design-system";

import RestrictContainerBox from 'components/RestrictContainerBox';
import { put, useGet } from 'utils/api';
import { actions } from 'redux/actions';
import { Dashboard } from "components/layouts/Dashboard";
import { isPaidOrTrialPlan, generateContactPlanUrl, isEnterprisePlan } from "utils/userUtils";
import messages from '../i18n/ReservationFinished';
import { apiUtils } from 'utils/apiUtils';
import { NoSupportWarning } from "components/layouts/NoSupportWarning";
import TemplatePermissionSelector, { convertToOption } from "components/forms/TemplatePermissionSelector";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import { useValidation } from "../../../utils/validation";
import PlanIcon from '../../../components/elements/PlanIcon';
import { PlanBlocked } from "../../../components/layouts/PlanBlocked";

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

const TCol = styled(Col)`
  margin-top: -18px;
  position: relative;
  top: 8px;
`;

const ReservationFinishedForm = formValueSelector("ReservationFinished");

function ReservationFinished(props) {
  const { useNotify, intl, initialize, handleSubmit, pristine, submitting } = props;
  const { uid, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const { setTemplateUid, saveWithTemplateSettings } = useSavedTemplateSettings();
  useGet(calendarSettingRequest(uid));
  const dispatch = useDispatch();
  const updateCalendarSetting = createAction(
    `${actions.getCalendarSetting.name}_update`
  );
  const { data, isSuccess } = useSelector((state) => state.calendarSetting);
  const notify = useNotify(intl);

  const permissions = useSelector(state => ReservationFinishedForm(state, "permissions"));
  const showAdminIcon = isTemplatePage || permissions?.completionMessage?.value === "administrator";
  const { url, required } = useValidation(intl);
  const customRedirect = useSelector((state) => state.form.ReservationFinished?.values.customRedirect) || false;

  useEffect(() => {
    if (isSuccess) {
      const {
        completedMessageWebpage,
        bookingCalendarTemplateUid,
        permissions,
        customRedirectUrl,
        customRedirect,
      } = data;
      setTemplateUid(bookingCalendarTemplateUid);
      initialize({
        completedMessageWebpage,
        permissions: convertToOption(permissions, intl),
        customRedirectUrl,
        customRedirect,
      });
    }
  }, [data]);

  function formatBody(values) {
    let body = {
      completed_message_webpage: values.completedMessageWebpage,
      custom_redirect_url: values.customRedirectUrl,
      custom_redirect: values.customRedirect || false,
    };
    return body;
  }

  function processResult(result) {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.notifyError, result?.data?.message);;
      throw new SubmissionError({ _error: "Invalid Data" });
    } else {
      notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
      dispatch(updateCalendarSetting(apiUtils.formatAxiosResponse(result)));
      return result;
    }
  }

  const onSubmit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }


  const handleOnSubmit = async (values) => {
    const url = `/booking_calendar_settings/${uid}`;
    const body = formatBody(values);

    const result = isTemplatePage ? await saveWithTemplateSettings(put(url,body), values.permissions) : await put(url, body);

    return processResult(result, body);
  };

  return (
    <>
      <PhoneBreakpoint>
        <NoSupportWarning />
      </PhoneBreakpoint>
      <DesktopBreakpoint>
        <Dashboard>
          <RestrictContainerBox
            isAvailable={isPaidOrTrialPlan()}
            guideText={
              <FormattedMessage
                {...messages.standard}
                values={{
                  br: <br />,
                  a: chunks => (
                    <a target="_blank" rel="noopener noreferrer" href={generateContactPlanUrl()}>
                      {chunks}
                    </a>
                  )
                }}
              />
            }
          >
            <form>
              <TemplateBlocked
                blocked={permissions?.completionMessage?.value}
                header={
                  <Row mt="40px" display="flex" justifyContent="end">
                    {isTemplatePage && (
                      <TCol>
                        <TemplatePermissionSelector name="completionMessage"/>
                      </TCol>
                    )}
                  </Row>
                }
                fields={
                  <>
                    <Row mt={showAdminIcon ? "40px" : "0px"}>
                      <Col xs>
                        <Field
                          id="customRedirect"
                          name="customRedirect"
                          trueFor={false}
                          component={RadioButton}
                        >
                          <FormattedMessage {...messages.customRedirect} />
                        </Field>
                      </Col>
                    </Row>
                    <PlanBlocked mt="26px" plan="enterprise">
                      <Row>
                        <Col xs display="flex">
                          <Field
                            id="customRedirect"
                            name="customRedirect"
                            trueFor={true}
                            component={RadioButton}
                          >
                            <FormattedMessage {...messages.customRedirectUrl} />
                          </Field>
                          <PlanIcon ml="2px" featurePlan="enterprise" />
                        </Col>
                      </Row>
                    </PlanBlocked>
                    {customRedirect && <Row mb="26px">
                      <Col xs>
                        <Field
                          id="customRedirectUrl"
                          name="customRedirectUrl"
                          component={Input}
                          placeholder={intl.formatMessage({ ...messages.customRedirectUrlPlaceholder })}
                          validate={[url, required]}
                        />
                      </Col>
                    </Row>}
                    {!customRedirect && <Row mb="26px" mt={!isEnterprisePlan() ? "40px" : "0px"}>
                      <Col xs>
                        <Row>
                          <Col xs>
                            <Field
                              id="completedMessageWebpage"
                              name="completedMessageWebpage"
                              component={TextAreaWithLabel}
                              labelLeft={intl.formatMessage({ ...messages.completedMessageWebpage })}
                              placeholder={intl.formatMessage({ ...messages.completedMessageWebpagePlaceholder })}
                              height="216px"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>}
                  </>
                }
              />

              <Hr border="none" height="1px" color="#e5e5e5" my="20px" />

              <Button
                disabled={pristine || submitting}
                onClick={handleSubmit((values) => onSubmit(values))}
                fontWeight="500"
              >
                <FormattedMessage {...messages.save} />
              </Button>
              <div style={{ height: "20px" }} />
            </form>
          </RestrictContainerBox>
        </Dashboard>
      </DesktopBreakpoint>
    </>
  )
}

ReservationFinished.propTypes = {};

export default reduxForm({
  form: "ReservationFinished"
})(injectIntl(ReservationFinished));
