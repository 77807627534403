/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { reduxForm, Field, formValueSelector, change, SubmissionError, getFormValues } from "redux-form";
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { v4 as uuid4 } from "uuid";
import { useParams } from "react-router-dom";
import { createStructuredSelector } from 'reselect';
import { find, findIndex, cloneDeep, isEqual } from "lodash";
import styled from "styled-components";
import {
  Button,
  Checkbox,
  IconNew as Icon,
  InputWithLabel,
  InputSelect,
  Label,
  Col,
  Hr,
  Row,
  ErrorText,
} from "@d-lighted/design-system";
import { Dashboard } from "components/layouts/Dashboard";
import { put } from "utils/api";
import { useValidation } from 'utils/validation';
import { isBookingTypeIrregular, isPremiumPlan } from "utils/userUtils";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import * as messageIdConstants from "components/layouts/constants/messageIdConstants"
import { BackLabel } from "components/elements/BackLabel";
import { MOBILEBREAKPOINT } from "constants/breakpoint";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";

import InputRestrictionField from "../modules/InputRestrictionField";
import { Radio } from "../modules/Radio";
import { BlockOfText } from "../modules/BlockOfText";
import { HiddenField } from "../modules/HiddenField";
import { formatCheckBoxesApi, formatTextAreaApi, formatTextFieldApi, formatInformationCustomFieldsApi, formatDateFieldApi } from "../utils/apiFormatter";
import { formatTextFieldObject, formatCheckBoxes, formatBlockOfTextObject, formatHiddenFieldObject } from "../utils/constants";
import { options, restrictionOptions } from "../utils/options";
import { useSearchSfInputFields } from "../hooks/useSearchSfFields";
import SalesforceField from "../modules/SalesforceField";
import messages from '../i18n/ReservationFormSetting';

const Header = styled.div`
  height: 15px;
  margin-top: 15px;
`;

const ReservationFormSettingsReader = formValueSelector("ReservationFormSettings");

const InputScreenSettingsReader = formValueSelector("InputScreenSettings");

const formSelector = state => getFormValues('ReservationFormSettings')(state);

const StyledErrorText = styled(ErrorText)`
  @media (max-width: ${MOBILEBREAKPOINT}){
    white-space: normal;
  }
`;

function ReservationFormSettings(props) {
  const { useNotify, intl, formValues, history, initialize } = props;

  const [defaultFormValues, setFormValues] = useState(formValues);
  const [baseField, setBaseField] = useState(false);
  const [showSfList, setSfShowList] = useState(false);
  const [sfAuthenticated, setSfState] = useState(false);
  const [showSfWarning, setSfWarning] = useState(false);
  const [sfFieldChoices, setSfFieldChoices] = useState([]);
  const isIrregular = isBookingTypeIrregular();

  const notify = useNotify(intl);
  const dispatch = useDispatch();
  const { uid, itemId, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const { required, inputLength, httpsUrl } = useValidation(intl);
  const { searchSfInputFieldValues } = useSearchSfInputFields();
  const selectableOptions = options(intl).filter(option => option.value !== 8);
  const { setTemplateUid, saveWithTemplateSettings } = useSavedTemplateSettings();

  /** Read the main from value */
  const fields = useSelector(state => InputScreenSettingsReader(state, "fields"));
  const { data, isSuccess } = useSelector((state) => state.calendarSetting);
  
  const companyNameFieldIsRequired = useSelector((state) =>
    InputScreenSettingsReader(state, "companyNameFieldIsRequired")
  );

  const guestNameFieldIsRequired = useSelector((state) =>
    InputScreenSettingsReader(state, "guestNameFieldIsRequired")
  );

  const companyNameFieldIsHidden = useSelector((state) =>
    InputScreenSettingsReader(state, "companyNameFieldIsHidden")
  );

  const guestNameFieldIsHidden = useSelector((state) =>
    InputScreenSettingsReader(state, "guestNameFieldIsHidden")
  );

  /** If main form is not initialized then dispatch
   * change action to initialize it
   * */
  useEffect(() => {
    if(fields == null) {
      history.goBack();
    }

    const baseFields = [
      {
        bookingCalendarSettingBookingCalendarCustomField: {
          inputRequired: companyNameFieldIsRequired,
          inputHidden: companyNameFieldIsHidden,
          textFieldInputRestriction: 'nothing'
        },
        fieldType: 'text_field',
        labelName: intl.formatMessage({...messages.companyNameField}),
        uid: 'company_name_field',
        baseField: true,
      },
      {
        bookingCalendarSettingBookingCalendarCustomField: {
          inputRequired: guestNameFieldIsRequired,
          inputHidden: guestNameFieldIsHidden,
          textFieldInputRestriction: 'nothing'
        },
        fieldType: 'text_field',
        labelName: intl.formatMessage({...messages.guestNameField}),
        uid: 'guest_name_field',
        baseField: true,
      },
      {
        bookingCalendarSettingBookingCalendarCustomField: {
          useOwnPrivacyPolicy: true,
          privacyPolicyUrl: null,
          textFieldInputRestriction: 'nothing'
        },
        fieldType: 'privacy_field',
        labelName: "privacy_policy_field",
        uid: 'privacy_policy_field',
        baseField: true,
      }
    ];

    let item = find([...(fields || []), ...baseFields], { uid: itemId });

    if (item) {
      const fieldType = find(options(intl), { type: item.fieldType });
      const salesforceFieldId = item.salesforceFieldId;
      const restrictionsOptions = restrictionOptions(intl);
      const inputRestriction =
        restrictionsOptions.find(option => option.type === item.bookingCalendarSettingBookingCalendarCustomField.textFieldInputRestriction);
      let blockOfTextValue = "";
      let hiddenFieldValue = "";

      if (item.bookingCalendarCustomFieldInputs) {
        blockOfTextValue = item.bookingCalendarCustomFieldInputs[0]?.inputTextAreaValue
        hiddenFieldValue = item.bookingCalendarCustomFieldInputs[0]?.inputValue
      }

      if(item.baseField === true) setBaseField(true);
      initialize({
        fieldType: fieldType,
        labelName: item.labelName,
        inputRequired: item?.bookingCalendarSettingBookingCalendarCustomField?.inputRequired,
        inputHidden: item?.bookingCalendarSettingBookingCalendarCustomField?.inputHidden,
        salesforceFieldId: salesforceFieldId,
        textFieldInputRestriction: inputRestriction || {
          value: 0,
          label: intl.formatMessage({ ...messages.freeField }),
          type: 'nothing',
        },
        choices: item.bookingCalendarMultipleChoiceOptions ||
          [{ bookingCalendarCustomFieldId: uuid4(), choiceTypeName: "", id: 0 }],
        blockOfText: blockOfTextValue,
        hiddenField: hiddenFieldValue,
        useOwnPrivacyPolicy: data?.useOwnPrivacyPolicy,
        privacyPolicyUrl: data?.privacyPolicyUrl,
      });

      if(salesforceFieldId) {
        saveSfFieldChoices(salesforceFieldId);
      }

    } else {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if(isSuccess) {
      const { companiesSalesforce, bookingCalendarTemplateUid } = data;
      setSfState(companiesSalesforce?.salesforceAuthenticated);
      setTemplateUid(bookingCalendarTemplateUid);
    }
  }, [data]);

  useEffect(() => {
    if(isPremiumPlan() && sfAuthenticated) {
      handleSfWarningState();
    }
  }, [formValues, sfFieldChoices]);

  const saveSfFieldChoices = (sfFieldId) => {
    searchSfInputFieldValues(sfFieldId).then(sfChoices => {
      setSfFieldChoices(sfChoices);
    });
  };

  const handleSfWarningState = () => {
    const form = formValues;
    if(["check_box", "radio_button", "dropdown"].includes(form?.fieldType?.type) && form?.salesforceFieldId) {
      if((sfFieldChoices || []).length !== 0) {
        const itemOptions = (form.choices || []).map(option => option?.choiceTypeName);
        const optionsMatch = itemOptions.every(option => sfFieldChoices.includes(option));
        if(!optionsMatch) {
          return setSfWarning(true);
        }
      }
    }
    setSfWarning(false);
  }

  /** Type of the filed reader */
  const { value } = useSelector((state) =>
    ReservationFormSettingsReader(state, "fieldType")
  );

  function processResult(result, value) {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.saveError, result?.data?.error?.message);
      throw new SubmissionError({ _error: "Error Occur" });
    } else {
      if(baseField) {
        switch (value.uid) {
          case "guest_name_field":
            dispatch(change("InputScreenSettings", "guestNameFieldIsRequired", value.bookingCalendarSettingBookingCalendarCustomField.inputRequired));
            dispatch(change("InputScreenSettings", "guestNameFieldIsHidden", value.bookingCalendarSettingBookingCalendarCustomField.inputHidden));
            break;
          default:
            dispatch(change("InputScreenSettings", "companyNameFieldIsRequired", value.bookingCalendarSettingBookingCalendarCustomField.inputRequired));
            dispatch(change("InputScreenSettings", "companyNameFieldIsHidden", value.bookingCalendarSettingBookingCalendarCustomField.inputHidden));
            break;
        }
      } else {
        let newArray = cloneDeep(fields);
        const index = findIndex(fields, { uid: itemId });
        newArray.splice(index, 1, value);
        dispatch(change("InputScreenSettings", "fields", newArray));
      }
      notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.saveSuccess);
      return result;
    }
  }

  const addFieldToSettings = async (fieldUid, value) => {
    const fieldType = find(options(intl), { type: value.fieldType });
    const url = `/booking_calendar_settings/${uid}/booking_calendar_custom_fields/${fieldUid}`;
    let body;
    switch (fieldType.value) {
      case 0: {
        body = formatTextFieldApi({
          uid: fieldUid,
          fieldType: value.fieldType,
          value,
          restrictionOptions: restrictionOptions(intl),
        }, baseField);
        break;
      }
      case 1: {
        body = formatTextAreaApi({
          uid: fieldUid,
          fieldType: value.fieldType,
          value,
        });
        break;
      }
      case 2:
      case 3: {
        body = formatCheckBoxesApi({
          uid: fieldUid,
          fieldType: value.fieldType,
          value,
        });
        break;
      }
      case 4: {
        body = formatInformationCustomFieldsApi({
          uid: fieldUid,
          fieldType: value.fieldType,
          value,
        });
        break;
      }
      case 5: {
        body = formatCheckBoxesApi({
          uid: fieldUid,
          fieldType: value.fieldType,
          value,
        });
        break;
      }
      case 6: {
        body = formatDateFieldApi({
          uid: fieldUid,
          fieldType: value.fieldType,
          value,
        }, baseField);
        break;
      }
      case 7: {
        body = formatInformationCustomFieldsApi({
          uid: fieldUid,
          fieldType: value.fieldType,
          value,
        });
        break;
      }
      case 8: {
        body = formatTextFieldApi({
          uid: fieldUid,
          fieldType: value.fieldType,
          value,
          restrictionOptions: restrictionOptions(intl),
        }, baseField);
        break;
      }
      default: {
        body = {};
        break;
      }
    }

    const result = isTemplatePage ? await saveWithTemplateSettings(put(url,body), {}) : await put(url, body);
    processResult(result, value);
  };


  const submit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

  async function handleOnSubmit({ fieldType, ...values }) {
    setSfShowList(false);
    setFormValues(formValues);

    const { value, type } = fieldType;
    let uid = itemId;
    if ([0, 1, 6, 8].includes(value)) {
      await addFieldToSettings(
        uid,
        formatTextFieldObject({ uid, fieldType: type, ...values })
      );
      return 0;
    }
    if ([2, 3, 5, 8].includes(value)) {
      await addFieldToSettings(
        uid,
        formatCheckBoxes({ uid, fieldType: type, ...values })
      );
      return 0;
    }
    if ([4].includes(value)) {
      await addFieldToSettings(
        uid,
        formatBlockOfTextObject({ uid, fieldType: type, ...values })
      );
      return 0;
    }
    if ([7].includes(value)) {
      await addFieldToSettings(
        uid,
        formatHiddenFieldObject({ uid, fieldType: type, ...values })
      );
      return 0;
    }
  }

  if (!defaultFormValues && formValues) {
    setFormValues(formValues);
  }

  const validatePrivacySettings = () => {
    const { useOwnPrivacyPolicy,  privacyPolicyUrl } = formValues;

    if(useOwnPrivacyPolicy && !privacyPolicyUrl) {
      const error = required(privacyPolicyUrl);
      return error != null ? <StyledErrorText>{error}</StyledErrorText> : null;
    }
  };

  return (
    <Dashboard>
      <Row mt="25px">
        <Col
          isCursor
          display="flex"
          alignItems="flex-end"
          onClick={() => {
            history.push({
              pathname: `/${settings}/${uid}/edit/booking_form/input_screen_settings`
            });
          }}
        >
          <Icon
            isCursor
            className="material-icons"
            fontWeight="100"
            fontSize="17px"
            color="#9bb0b5"
          >
            navigate_before
          </Icon>
          <BackLabel fontSize="13px">
            <FormattedMessage {...messages.backToList} />
          </BackLabel>
        </Col>
      </Row>
      <TemplateBlocked
        blocked={data?.permissions?.fields}
        header={<Header />}
        showHeaderOnlyOnBlock
        fields={
          <>
            { baseField && value !== 8 && (
              <Row mt="25px">
                <Col xs>
                  <Field
                    id="inputHidden"
                    name="inputHidden"
                    component={Checkbox}
                    useArrayValue={false}
                  >
                    <FormattedMessage {...messages.hiddenField} />
                  </Field>
                </Col>
              </Row>
            )}
            {![8].includes(value) &&
              <>
                <Row mt="25px">
                  <Col xs>
                    <Label>
                      <FormattedMessage {...messages.fieldType} />
                    </Label>
                  </Col>
                </Row>
                <Row mt="15px">
                  <Col xs>
                    <Field
                      id="fieldType"
                      name="fieldType"
                      component={InputSelect}
                      options={selectableOptions}
                      isSearchable={false}
                      height="44px"
                      isDisabled={baseField}
                    />
                  </Col>
                </Row>
              </>
            }
            {![7,8].includes(value) &&
              <Row mt="25px">
                <Col xs>
                  <Field
                    id="labelName"
                    name="labelName"
                    component={InputWithLabel}
                    labelLeft={value === 4
                      ? <FormattedMessage {...messages.messageTitle} />
                      : <FormattedMessage {...messages.questionToGuest} />
                    }
                    height="44px"
                    placeholder={value === 4
                      ? intl.formatMessage({ ...messages.messagePlaceholder })
                      : intl.formatMessage({ ...messages.questionPlaceholder })
                    }
                    validate={[required, inputLength]}
                  disabled={baseField}
                />
              </Col>
            </Row>}

            {value === 0 && !baseField && <InputRestrictionField options={restrictionOptions(intl)} {...props} />}

            {[2, 3, 5].includes(value) && <Radio {...props} />}

            {[4].includes(value) && <BlockOfText {...props} />}
            {[7].includes(value) && <HiddenField {...props} />}

            {![4].includes(value) && !baseField && !isIrregular &&
              <SalesforceField
                authenticated={sfAuthenticated}
                showList={showSfList}
                setShowList={setSfShowList}
                saveSfFieldChoices={saveSfFieldChoices}
                {...props}
              />
            }

            {![4,7,8].includes(value) && <Row mt="35px">
              <Col xs>
                <Field
                  id="inputRequired"
                  name="inputRequired"
                  component={Checkbox}
                  useArrayValue={false}
                >
                  <FormattedMessage {...messages.requiredField} />
                </Field>
              </Col>
            </Row>}
            {[8].includes(value) &&
              <>
                <Row mt="35px">
                  <Col xs display='flex' alignItems='center'>
                    <Field
                      id="useOwnPrivacyPolicy"
                      name="useOwnPrivacyPolicy"
                      component={Checkbox}
                      useArrayValue={false}
                    >
                      <FormattedMessage {...messages.useOwnPrivacyPolicy} />
                    </Field>
                  </Col>
                </Row>
                <Row mt="25px">
                  <Col xs>
                    <Field
                      id="privacyPolicyUrl"
                      name="privacyPolicyUrl"
                      component={InputWithLabel}
                      labelLeft={<FormattedMessage {...messages.privacyPolicyUrl} /> }
                      placeholder={"https://help.receptionist.jp/?p=402" }
                      height="44px"
                      validate={[inputLength, httpsUrl]}
                    />
                  </Col>
                </Row>
                {validatePrivacySettings()}
              </>
            }
          </>
        }
      />
      <Hr border="none" height="1px" color="#e5e5e5" mt="20px" />

      {showSfWarning &&
        <Row mt="15px">
          <Col xs>
            <PlanBlocked
              plan="premium"
              messageId={messageIdConstants.sfFieldMatchError}
            >
              <div/>
            </PlanBlocked>
          </Col>
        </Row>
      }
      <Row mt="20px">
        <Col xs>
          <Button
            disabled={
              (formValues?.useOwnPrivacyPolicy && !formValues?.privacyPolicyUrl) ||
              isEqual(defaultFormValues, formValues)
            }
            onMouseDown={props.handleSubmit(submit)}
            fontWeight="500"
          >
            <FormattedMessage {...messages.save} />
          </Button>
        </Col>
      </Row>
    </Dashboard>
  );
}

const mapStateToProps = createStructuredSelector({
  formValues: formSelector,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'ReservationFormSettings',
    initialValues: {
      fieldType: {
        value: 0,
        label: "テキストフィールド",
        type: "text_field",
      },
      textFieldInputRestriction: {
        value: 0,
        label: "<FormattedMessage {...messages.freeField} />",
        type: 'nothing',
      },
      choices: [{ bookingCalendarCustomFieldId: uuid4(), choiceTypeName: "" }],
    },
  }),
  injectIntl,
)(ReservationFormSettings);
