import { defineMessages } from 'react-intl';

export default defineMessages({
  fullName: {
    id: "components.form.memberSelection.fullName",
    defaultMessage: "Full Name",
  },
  attendee: {
    id: "components.form.memberSelection.attendee",
    defaultMessage: "Attendee",
  },
  addEmployees: {
    id: "components.form.memberSelection.addEmployees",
    defaultMessage: "Add Employees",
  },
  searchEmployees: {
    id: "components.form.memberSelection.searchEmployees",
    defaultMessage: "Search Employees",
  },
  calendarNotIntegrated: {
    id: 'components.form.memberSelection.calendarNotIntegrated',
    defaultMessage: 'Calendar has not been linked yet\nMore details <a>here</a>',
  },
  zoomNotAuthorized: {
    id: 'components.form.memberSelection.zoomNotIntegrated',
    defaultMessage: 'Zoom integration has not been completed',
  },
  webexNotAuthorized: {
    id: 'components.form.memberSelection.webexNotIntegrated',
    defaultMessage: 'Webex integration has not been completed',
  },
  close: {
    id: "common.close",
    defaultMessage: "Close",
  },
  mustAttendees: {
    id: "components.form.memberSelection.mustAttendArea.mustAttend",
    defaultMessage: "Mandatory attendees",
  },
  optionalAttendees: {
    id: "components.form.memberSelection.mustAttendArea.optionalAttendees",
    defaultMessage: "Optional attendees",
  },
  editors: {
   id: "components.form.memberSelection.mustAttendArea.editors",
   defaultMessage: "Editors"
  },
  noSuchMember: {
    id: 'components.form.memberSelection.noSuchMember',
    defaultMessage: 'To invite a member for the first time, please enter an email address',
  },
  sendEmailInvitation: {
    id: 'components.form.memberSelection.sendEmailInvitation',
    defaultMessage: 'Send email invitation: email@address.com',
  },
  approvalPending: {
    id:'components.form.memberSelection.approvalPending',
    defaultMessage: 'Approval Pending'
  },
  inviteError: {
    id: 'components.form.memberSelection.inviteError',
    defaultMessage: 'Failed to invite new member'
  },
  inviteSuccess: {
    id: 'components.form.memberSelection.inviteSuccess',
    defaultMessage: 'An invitation email has been sent to the added employees.'
  },
  deleteSuccess: {
    id: 'components.form.memberSelection.deleteSuccess',
    defaultMessage: 'Deleted employee awaiting approval.'
  },
  deleteError: {
    id: 'components.form.memberSelection.deleteError',
    defaultMessage: 'Failed to delete employee record'
  },
  attendeesLimitCustom: {
    id: 'views.meetings.pages.meetings.attendeesLimitCustom',
    defaultMessage: 'Max allowed must attendees and optional attendees is {count}',
  },
});
